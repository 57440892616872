import { useLazyQuery } from '@apollo/client';
import { Transition } from '@headlessui/react';
import {
  Query,
  QueryUserProjectsArgs,
  UserFavoriteProjectsQuery,
  userProjectsQueryVars,
} from '@typevid/graphql';
import { Empty, transitionProps, useAuth } from '@typevid/ui-shared';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import {
  ProjectsListItem,
  useOnToggleFavorite,
} from '../projects/projects-list-item';
import { ProjectsListItemLoading } from '../projects/projects-list-item-loading';

const Loading: React.FC = React.memo(() => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 animate-pulse">
      {Array(3)
        .fill(0)
        .map((_, i) => (
          <ProjectsListItemLoading key={i} />
        ))}
    </div>
  );
});

export const DashboardProjects: React.FC = () => {
  const router = useRouter();
  const onToggleFavorite = useOnToggleFavorite();
  const { scope } = useAuth();

  const [fetchUserFavoriteProjects, { loading, data, error }] = useLazyQuery<
    { userFavoriteProjects: Query['userFavoriteProjects'] },
    QueryUserProjectsArgs
  >(UserFavoriteProjectsQuery);

  useEffect(() => {
    fetchUserFavoriteProjects({
      variables: userProjectsQueryVars,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center space-x-4">
        <h3 className="leading-none font-medium text-base">
          Favorite Projects
        </h3>
        <span className="h-px flex-1 bg-gray-300" />
      </div>
      <div
        className={clsx('relative min-h-[14rem]', loading && 'overflow-hidden')}
      >
        <Transition
          {...transitionProps.loading}
          show={loading}
          className="absolute inset-x-0"
        >
          <Loading />
        </Transition>
        <Transition
          show={!loading && !error && data?.userFavoriteProjects?.length === 0}
          {...transitionProps.loaded}
        >
          <Empty
            title="No Favorite Projects"
            messages={[
              'Click on the star icon to add a project to your favorites.',
            ]}
            actionLabel="View Projects"
            onActionClick={() => router.push(`${scope}/projects`)}
            icon={
              <svg
                className="h-32 w-32 mb-2 text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
              </svg>
            }
          />
        </Transition>
        <Transition
          show={!loading && !!error && error?.graphQLErrors?.length > 0}
          {...transitionProps.loaded}
        >
          <Empty
            title="Oops! Something went wrong!"
            type="error"
            messages={error?.graphQLErrors.map(({ message }) => message) ?? []}
          />
        </Transition>
        <Transition
          show={!loading && !error && data?.userFavoriteProjects?.length !== 0}
          {...transitionProps.loaded}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {data?.userFavoriteProjects?.map((node) => (
              <ProjectsListItem
                key={node.id}
                id={node.id}
                name={node.name}
                count={node.totalMedias}
                templateId={node.templateId}
                date={node.createdAt}
                favorite={node.favorite}
                scope={scope}
                onFavoriteClick={(...args) => onToggleFavorite(...args, node)}
              />
            ))}
          </div>
        </Transition>
      </div>
    </div>
  );
};

export default DashboardProjects;
