import React from 'react';
import { Query, RecentMediasQuery } from '@typevid/graphql';
import { NetworkStatus, useQuery } from '@apollo/client';
import { ProjectMedias } from '../project/project-medias';

export const DashboardMedias: React.FC = () => {
  const { loading, data, error, networkStatus, refetch } = useQuery<{
    recentMedias: Query['recentMedias'];
  }>(RecentMediasQuery, {
    pollInterval: 60000,
    notifyOnNetworkStatusChange: true,
  });

  return (
    <ProjectMedias
      loading={loading}
      networkLoading={networkStatus === NetworkStatus.loading}
      data={data}
      error={error}
      refetch={refetch}
      source="dashboard"
    />
  );
};

export default DashboardMedias;
