import React, { useCallback, useEffect, useState } from 'react';
import {
  Layout,
  LayoutHeader,
  useAuth,
  useSetBreadcrumbState,
} from '@typevid/ui-shared';
import { ProjectsNewItem } from './projects-new-item';
import { ProjectsCreate } from './projects-create';
import DashboardMenu from '../dashboard/dashboard-menu';

export const ProjectsNew: React.FC = () => {
  const [isCreateShown, setIsCreateShown] = useState<boolean>(false);
  const setBreadcrumbState = useSetBreadcrumbState();
  const { scope } = useAuth();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setBreadcrumbState(() => ({
      show: true,
      data: {
        0: { href: `/${scope}/projects`, label: 'Dashboard' },
        1: { href: `/${scope}/projects/new`, label: 'New Project' },
      },
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSelect = useCallback((id: string) => {
    console.log('Id', id);
  }, []);

  return (
    <Layout
      title="New Project"
      header={
        <>
          <DashboardMenu activeTab="projects" />
          <LayoutHeader title="New Project" />
        </>
      }
    >
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="sm:px-0 px-4 py-6">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <ProjectsNewItem
              id="BLANK"
              name="Blank Project"
              imgUrl="/blank-project.jpg"
              description="Start from a blank canvas and build your project."
              category="Preset"
              onSelect={() => setIsCreateShown(true)}
            />
            {Array(5)
              .fill(0)
              .map((_, i) => (
                <ProjectsNewItem
                  key={i}
                  id={`template${i + 1}`}
                  name={`Template #${i + 1}`}
                  imgUrl="https://placeimg.com/640/360/tech"
                  description=""
                  category="Preset"
                  onSelect={handleOnSelect}
                />
              ))}
          </div>
        </div>
      </div>
      <ProjectsCreate
        shown={isCreateShown}
        onClose={() => setIsCreateShown(false)}
      />
    </Layout>
  );
};

export default ProjectsNew;
