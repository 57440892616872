import React from 'react';
import { Layout, LayoutHeader } from '@typevid/ui-shared';

import { AccountMenu } from './account-menu';

export const Account: React.FC = () => {
  return (
    <Layout title="Account" header={<AccountMenu activeTab="dashboard" />}>
      <LayoutHeader title="Dashboard" />
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <div className="space-y-12 py-6"></div>
      </div>
    </Layout>
  );
};

export default Account;
