import React from 'react';
import Link from 'next/link';
import {
  EditorStatus,
  exportsFileTypesIcons,
  LayoutHeader,
} from '@typevid/ui-shared';
import { MediaExport, MediaExportStatusType } from '@typevid/graphql';
import { Maybe } from '@typevid/ui-data';
import clsx from 'clsx';

const EXPORT_COLORS: Record<keyof typeof exportsFileTypesIcons, string> = {
  mp4: 'bg-purple-600',
  mov: 'bg-purple-600',
  webm: 'bg-purple-600',
  gif: 'bg-teal-700',
  apng: 'bg-teal-700',
  jpeg: 'bg-blue-600',
  png: 'bg-blue-600',
  pdf: 'bg-red-600',
};

interface ExportAvatarProps {
  fileType: Maybe<string> | undefined;
  small?: boolean;
}
export const ExportAvatar: React.FC<ExportAvatarProps> = React.memo(
  ({ fileType, small = false }) => {
    return (
      <div
        className={clsx(
          'flex flex-col items-center justify-center h-full w-full text-white select-none',
          EXPORT_COLORS?.[fileType as keyof typeof exportsFileTypesIcons] ??
            'bg-gray-500'
        )}
      >
        <span
          className={small ? 'w-4 s:h-4 sm:inline-block hidden' : 'w-5 h-5'}
          dangerouslySetInnerHTML={{
            __html:
              exportsFileTypesIcons?.[
                fileType as keyof typeof exportsFileTypesIcons
              ] ?? '',
          }}
        />
        <span className={clsx('uppercase', small ? 'text-xs' : 'text-sm')}>
          {fileType}
        </span>
      </div>
    );
  }
);

export interface ExportHeaderProps {
  loading: boolean;
  data: MediaExport | null;
}

export const ExportHeader: React.FC<ExportHeaderProps> = ({
  loading,
  data,
}) => {
  return (
    <LayoutHeader
      loading={loading}
      title={`${data?.fileType?.toUpperCase()} - ${data?.format}`}
      titleTag={data?.status ? <EditorStatus status={data?.status} /> : null}
    >
      <div className="flex items-center space-x-2">
        {loading || !data ? (
          <span className="w-full sm:w-auto bg-gray-200 text-gray-200 border border-transparent px-4 py-2 rounded-md animate-pulse text-sm font-medium">
            Download Exp
          </span>
        ) : (
          data?.status === MediaExportStatusType.Exported &&
          !!data?.url && (
            <Link download={true} href={data.url}>
              <a
                download={true}
                href={data.url}
                className="inline-flex w-full sm:w-auto justify-center sm:justify-start px-4 py-2 border border-transparent rounded-md text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 select-none focus:outline-none focus-visible:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <svg
                  className="-ml-1 mr-2 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  enableBackground="new 0 0 24 24"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path>
                </svg>
                <span>Download</span>
              </a>
            </Link>
          )
        )}
      </div>
    </LayoutHeader>
  );
};

export default ExportHeader;
