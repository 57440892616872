import { useRouter } from 'next/router';
import { NetworkStatus, useQuery } from '@apollo/client';
import {
  Query,
  QueryRecentExportsArgs,
  RecentExportsQuery,
  RecentMediasQuery,
} from '@typevid/graphql';
import { Layout, Empty } from '@typevid/ui-shared';
import { Transition } from '@headlessui/react';

import { ProjectMediasCreate } from './project-medias-create';
import { ProjectHeader } from './project-header';
import { ProjectExports } from './project-exports';
import { ProjectMenu } from './project-menu';

import { useProject } from './project.hooks';
import { ProjectMedias } from './project-medias';

export const Project: React.FC = () => {
  const router = useRouter();
  const {
    data,
    error,
    loading,
    fromCache,
    scope,
    isCreateShown,
    setIsCreateShown,
  } = useProject();

  const recentExportsData = useQuery<
    {
      recentExports: Query['recentExports'];
    },
    QueryRecentExportsArgs
  >(RecentExportsQuery, {
    variables: { projectId: router.query?.projectId as string },
    pollInterval: 60000,
    notifyOnNetworkStatusChange: true,
  });

  const recentMediaData = useQuery<{
    recentMedias: Query['recentMedias'];
  }>(RecentMediasQuery, {
    variables: { projectId: router.query?.projectId as string },
    pollInterval: 60000,
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    return (
      <Layout title="Project">
        <Transition
          show={!loading && !!error}
          enter={`transition ease-out duration-300 delay-900`}
          enterFrom="transform opacity-0"
          enterTo="transform opacity-100"
          leave="transition ease-in duration-300"
          leaveFrom="transform opacity-100"
          leaveTo="transform opacity-0"
          className="absolute inset-0 top-16"
        >
          <Empty
            title="Oops! Something went wrong!"
            type="error"
            messages={error.graphQLErrors.map(({ message }) => message)}
            actionLabel="Go To Dashboard"
            onActionClick={() => router.push(`/${scope}`)}
          />
        </Transition>
      </Layout>
    );
  }

  return (
    <Layout
      title={data?.name || 'Project'}
      header={
        <>
          <ProjectMenu
            activeTab="project"
            projectId={router.query?.projectId as string}
          />
          <ProjectHeader
            loading={loading && !fromCache}
            data={data}
            scope={scope}
            onCreate={() => setIsCreateShown(true)}
          />
        </>
      }
    >
      <div className="relative">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <div className="relative space-y-12 py-6">
            <ProjectExports
              title="Recent Exports"
              loading={recentExportsData.loading}
              networkLoading={
                recentExportsData.networkStatus === NetworkStatus.loading
              }
              data={recentExportsData.data}
              error={recentExportsData.error}
              refetch={recentExportsData.refetch}
            />

            <ProjectMedias
              title="Recent Medias"
              loading={recentMediaData.loading}
              networkLoading={
                recentMediaData.networkStatus === NetworkStatus.loading
              }
              data={recentMediaData.data}
              error={recentMediaData.error}
              refetch={recentMediaData.refetch}
            />
          </div>
        </div>
        {data && (
          <ProjectMediasCreate
            shown={isCreateShown}
            projectId={data.id}
            defaultTitle={`Media #${(data.totalMedias ?? 0) + 1}`}
            onClose={() => setIsCreateShown(false)}
          />
        )}
      </div>
    </Layout>
  );
};

export default Project;
