import React, { useMemo } from 'react';
import { Transition } from '@headlessui/react';
import {
  EditorStatus,
  Empty,
  formatDate,
  transitionProps,
  useAuth,
} from '@typevid/ui-shared';
import Link from 'next/link';
import { MediaModeType, Query, QueryRecentMediasArgs } from '@typevid/graphql';
import { ApolloError, ApolloQueryResult } from '@apollo/client';
import clsx from 'clsx';
import { Tooltip } from 'react-tippy';

interface MediaItemProps {
  id: string;
  title: string;
  updatedAt: string;
  scope: string | null | undefined;
  mode: MediaModeType;
  project: { id: string; name: string };
  loading: boolean;
  showProjectName?: boolean;
}
const MediaItem: React.FC<MediaItemProps> = React.memo(
  ({
    id,
    title,
    updatedAt,
    mode,
    scope,
    project,
    loading,
    showProjectName = true,
  }) => {
    const link = useMemo(
      () =>
        mode === MediaModeType.Edit
          ? `/${scope}/editor/${id}`
          : `/${scope}/${project.id}/${id}`,
      [id, mode, project.id, scope]
    );
    return (
      <div className="bg-white shadow-sm rounded-md text-sm overflow-hidden select-none transition-outline duration-100">
        <div className="py-4 px-4 space-y-0.5">
          <div className="flex items-center  max-w-full space-x-2">
            <div className="truncate font-medium">
              <Link href={link}>
                <a
                  href={link}
                  className="block truncate hover:text-indigo-500 focus-visible:text-indigo-500 focus:outline-none focus-visible:underline"
                >
                  {title}
                </a>
              </Link>
            </div>
            <div className="inline-flex shrink-0">
              <EditorStatus
                status={!loading ? mode : ('···' as MediaModeType)}
              />
            </div>
          </div>

          <div className="flex items-center max-w-full text-gray-500">
            {showProjectName && (
              <>
                <div className="truncate">
                  In{' '}
                  <Link href={`/${scope}/${project?.id}`}>
                    <a
                      href={`/${scope}/${project?.id}`}
                      className="hover:text-gray-700 focus:outline-none focus-visible:underline focus-visible:text-indigo-500"
                    >
                      {project?.name}
                    </a>
                  </Link>
                </div>
                <span>&nbsp;-&nbsp;</span>
              </>
            )}

            <div className="flex-shrink-0">
              Updated{' '}
              {loading ? (
                <span>···</span>
              ) : (
                formatDate(updatedAt as string, 'D')
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

const Loading: React.FC = React.memo(() => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 animate-pulse">
      {Array(9)
        .fill(0)
        .map((_, i) => (
          <div key={i} className="bg-gray-300 rounded-md overflow-hidden">
            <div className="py-4 px-4 space-y-0.5 invisible">
              <div className="inline-flex items-center justify-between max-w-full space-x-2">
                <div className="truncate font-medium">Title</div>
                <EditorStatus status={MediaModeType.Edit} />
              </div>

              <div className="text-sm truncate text-gray-500 invisible">
                <span>Project & date</span>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
});

export interface ProjectMediasProps {
  title?: string;
  source?: 'dashboard' | 'project';
  loading: boolean;
  networkLoading: boolean;
  error: ApolloError | undefined;
  data:
    | {
        recentMedias: Query['recentMedias'];
      }
    | undefined;

  refetch: (variables?: Partial<QueryRecentMediasArgs> | undefined) => Promise<
    ApolloQueryResult<{
      recentMedias: Query['recentMedias'];
    }>
  >;
}

const ProjectMediasComponent: React.FC<ProjectMediasProps> = ({
  title = 'Recent Medias',
  source = 'project',
  data,
  error,
  loading,
  networkLoading,
  refetch,
}) => {
  const { scope } = useAuth();
  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center space-x-4">
        <h3 className="leading-none font-medium text-base">{title}</h3>
        <span className="h-px flex-1 bg-gray-300" />
        <div>
          <Tooltip
            size="small"
            position="top"
            disabled={loading}
            arrow={true}
            duration={150}
            html={
              <span>
                Refresh
                <span className="block text-xs">
                  (Auto-refreshes every 60 seconds)
                </span>
              </span>
            }
          >
            <button
              className={clsx(
                'flex justify-center items-center px-2 py-1 rounded-md bg-white border border-gray-300 select-none focus:outline-none focus-visible:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                loading
                  ? 'bg-gray-300 text-gray-400'
                  : 'hover:bg-gray-50 text-gray-500 focus-lg'
              )}
              disabled={loading}
              onClick={() => refetch()}
            >
              <svg
                className={clsx('h-5 w-5', loading && 'animate-spin')}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M12 4V1L8 5l4 4V6c3.31 0 6 2.69 6 6 0 1.01-.25 1.97-.7 2.8l1.46 1.46C19.54 15.03 20 13.57 20 12c0-4.42-3.58-8-8-8zm0 14c-3.31 0-6-2.69-6-6 0-1.01.25-1.97.7-2.8L5.24 7.74C4.46 8.97 4 10.43 4 12c0 4.42 3.58 8 8 8v3l4-4-4-4v3z" />
              </svg>
            </button>
          </Tooltip>
        </div>
      </div>
      <div
        className={clsx(
          'relative min-h-[5rem] max-h-[16.625rem] lg:max-h-[none]',
          networkLoading
            ? 'h-[16.625rem] overflow-hidden'
            : 'overflow-y-auto lg:overflow-hidden'
        )}
      >
        <Transition
          {...transitionProps.loading}
          show={networkLoading}
          className="absolute inset-x-0"
        >
          <Loading />
        </Transition>
        <Transition
          {...transitionProps.loaded}
          show={!loading && !error && data?.recentMedias?.length === 0}
        >
          <Empty
            title="No Medias"
            messages={['No medias created yet.']}
            icon={
              <svg
                className="h-32 w-32 text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M2.5 3.5a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-11zm2-2a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6v7zm6.258-6.437a.5.5 0 0 1 .507.013l4 2.5a.5.5 0 0 1 0 .848l-4 2.5A.5.5 0 0 1 6 12V7a.5.5 0 0 1 .258-.437z"></path>
              </svg>
            }
          />
        </Transition>
        <Transition
          show={!loading && !!error && error?.graphQLErrors?.length > 0}
          {...transitionProps.loaded}
        >
          <Empty
            title="Oops! Something went wrong!"
            type="error"
            messages={error?.graphQLErrors.map(({ message }) => message) ?? []}
          />
        </Transition>
        <Transition
          {...transitionProps.loaded}
          show={!error && data?.recentMedias.length !== 0}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {data?.recentMedias?.map(
              ({ id, title, mode, project, updatedAt }) => (
                <MediaItem
                  key={id}
                  id={id}
                  title={title}
                  mode={mode}
                  project={project}
                  scope={scope}
                  updatedAt={updatedAt}
                  loading={loading}
                  showProjectName={source !== 'project'}
                />
              )
            )}
          </div>
        </Transition>
      </div>
    </div>
  );
};

export const ProjectMedias = React.memo(ProjectMediasComponent);
export default ProjectMediasComponent;
