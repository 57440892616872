import React, { Fragment, useCallback, useRef, useState } from 'react';
import { Transition } from '@headlessui/react';
import { Spinner, useAuth, useDebouncy } from '@typevid/ui-shared';
import clsx from 'clsx';
import { useMutation } from '@apollo/client';
import {
  Mutation,
  CreateMediaInput,
  CreateMediaMutation,
  Query,
  UserProjectQuery,
  UserProjectMediasQuery,
  userProjectMediasQueryVars,
  RecentMediasQuery,
} from '@typevid/graphql';
import { useRouter } from 'next/router';
import { AlertProviderProps, useAlert } from 'react-alert';

export interface ProjectMediasCreate {
  shown: boolean;
  projectId: string;
  defaultTitle: string;
  onClose: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const mediasCreateValidate = {
  title: (title: string) => {
    const l = title.trim().length;
    if (l === 0) return 'Enter a media title.';
    if (l > 140) return 'Must be less than 140 characters.';
    return null;
  },
};

export const ProjectMediasCreate: React.FC<ProjectMediasCreate> = ({
  shown = false,
  projectId,
  defaultTitle,
  onClose,
}) => {
  const focusedInputRef = useRef<HTMLInputElement>(null);
  const alert = useAlert();
  const router = useRouter();
  const { scope } = useAuth();
  const [{ error, title }, setState] = useState<{
    title: string;
    error: {
      title: string | null;
    };
  }>({
    title: defaultTitle,
    error: { title: null },
  });

  useDebouncy(
    () => {
      setState((s) => ({
        ...s,
        error: {
          ...s.error,
          title: mediasCreateValidate.title(title),
        },
      }));
    },
    100,
    [title]
  );

  const [createMedia, { loading }] = useMutation<
    {
      createMedia: Mutation['createMedia'];
    },
    CreateMediaInput
  >(CreateMediaMutation, {
    refetchQueries: [
      { query: RecentMediasQuery },
      { query: RecentMediasQuery, variables: { projectId } },
    ],
    onError: (error) =>
      error.graphQLErrors.forEach(({ message }) => {
        alert.error(
          <>
            <h4 className="text-sm font-medium">An error has occurred!</h4>
            <p className="text-xs">{message}</p>
          </>,
          {
            timeout: 0,
            position: 'top right',
          } as AlertProviderProps
        );
      }),
    onCompleted: (data) => {
      router.push(`/${scope}/editor/${data?.createMedia?.id}`);
    },
    update(cache, { data }) {
      if (!data) return;
      // Update UserProjectQuery
      const userProjectData = cache.readQuery<{
        userProject: Query['userProject'];
      }>({
        query: UserProjectQuery,
        variables: { id: data?.createMedia?.project?.id },
      });
      if (userProjectData?.userProject) {
        cache.writeQuery<{
          userProject: Query['userProject'];
        }>({
          query: UserProjectQuery,
          variables: { id: data?.createMedia?.project?.id },
          data: {
            userProject: {
              ...userProjectData?.userProject,
              totalMedias: (userProjectData?.userProject?.totalMedias ?? 0) + 1,
            },
          },
        });
      }

      // Update userProjectMedias
      const userProjectMediasData = cache.readQuery<{
        userProjectMedias: Query['userProjectMedias'];
      }>({
        query: UserProjectMediasQuery,
        variables: {
          ...userProjectMediasQueryVars,
          id: router.query?.projectId as string,
        },
      });
      if (userProjectMediasData?.userProjectMedias?.edges) {
        cache.writeQuery<{
          userProjectMedias: Query['userProjectMedias'];
        }>({
          query: UserProjectMediasQuery,
          variables: {
            ...userProjectMediasQueryVars,
            id: router.query?.projectId as string,
          },
          data: {
            userProjectMedias: {
              ...userProjectMediasData.userProjectMedias,
              totalCount:
                userProjectMediasData.userProjectMedias.totalCount + 1,
              edges: [
                {
                  node: { ...data.createMedia },
                  cursor: data.createMedia.id,
                  __typename: 'MediaEdge',
                },
                ...userProjectMediasData.userProjectMedias.edges,
              ],
              pageInfo: {
                ...userProjectMediasData.userProjectMedias.pageInfo,
                startCursor: data.createMedia.id,
              },
            },
          },
        });
      }
    },
  });

  const handleOnCreate = useCallback(() => {
    const _error: typeof error = {
      title: mediasCreateValidate.title(title),
    };
    if (
      (Object.keys(_error) as Array<keyof typeof error>).every(
        (k) => !_error[k]
      )
    ) {
      createMedia({
        variables: {
          title,
          projectId,
        },
      });
    } else {
      setState((s) => ({
        ...s,
        error: _error,
      }));
    }
  }, [createMedia, title, projectId]);

  return (
    <div
      className={clsx(
        'fixed inset-0 z-50 overflow-hidden select-none',
        !shown && 'pointer-events-none'
      )}
      aria-labelledby="slide-over-title"
      role="dialog"
      aria-modal="true"
    >
      <Transition show={shown}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            enter="ease-in-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            as="button"
            type="button"
            aria-hidden="true"
            onClick={!loading ? onClose : () => false}
            className={clsx(loading ? 'cursor-wait' : 'cursor-pointer')}
          >
            <div
              className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
          </Transition.Child>

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-200"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-200"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative w-screen max-w-md">
                {!loading && (
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-200"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterEnter={() => focusedInputRef.current?.focus()}
                  >
                    <div className="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
                      <button
                        className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={!loading ? onClose : () => false}
                      >
                        <span className="sr-only">Close panel</span>

                        <svg
                          className="h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  </Transition.Child>
                )}

                <div className="h-full flex flex-col bg-white shadow-xl overflow-y-auto">
                  <div className="px-4 sm:px-6 mt-6">
                    <h2
                      id="slide-over-heading"
                      className="text-lg font-medium text-gray-900"
                    >
                      Create New Media
                    </h2>
                  </div>
                  <div className="mt-6 relative flex-1">
                    <div className="absolute inset-0 flex flex-col">
                      <div className="flex-1">
                        <div className="grid gap-4 px-4 sm:px-6">
                          <div>
                            <label
                              htmlFor="title"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Title
                            </label>
                            <div className="mt-1 flex">
                              <input
                                ref={focusedInputRef}
                                type="text"
                                name="title"
                                id="title"
                                value={title}
                                autoComplete="off"
                                autoCorrect="off"
                                className={clsx(
                                  'text-sm focus:outline-none flex-1 p-2 block w-full rounded-md border',
                                  error?.title
                                    ? 'border-red-500 focus:border-red-500'
                                    : 'border-gray-300 focus:ring-indigo-600 focus:border-indigo-600'
                                )}
                                placeholder="My Media"
                                onChange={(e) =>
                                  setState((s) => ({
                                    ...s,
                                    title: e.target.value,
                                  }))
                                }
                              />
                            </div>
                            {!!error?.title && (
                              <div className="mt-2 text-sm p-2 pl-3 rounded-[0.25rem] text-red-600 bg-gray-50 border-l-4 border-red-500">
                                <p>{error.title}</p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="mt-6 px-4 py-3 space-x-2 bg-gray-50 text-right sm:px-6">
                        {!loading && (
                          <button
                            type="button"
                            className={clsx(
                              'inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 select-none focus:outline-none focus-visible:ring-2 focus:ring-offset-2 focus:ring-indigo-600'
                            )}
                            onClick={onClose}
                          >
                            Cancel
                          </button>
                        )}
                        <button
                          disabled={loading}
                          type="button"
                          className={`transition-all inline-flex justify-center items-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white ${
                            loading
                              ? 'bg-indigo-700 cursor-default opacity-50'
                              : 'bg-indigo-600'
                          } hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600`}
                          onClick={handleOnCreate}
                        >
                          {loading && (
                            <Spinner className="text-white w-4 h-4 mr-2" />
                          )}{' '}
                          Create
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default ProjectMediasCreate;
