import React, { useCallback, useEffect } from 'react';
import { ApolloError } from '@apollo/client';
import clsx from 'clsx';
import { useTable, useSortBy, usePagination, Column } from 'react-table';
import { Empty } from '@typevid/ui-shared';

// Define a default UI for filtering
// function GlobalFilter({
//   preGlobalFilteredRows,
//   globalFilter,
//   setGlobalFilter,
// }) {
//   const count = preGlobalFilteredRows.length;
//   const [value, setValue] = React.useState(globalFilter);
//   const onChange = useAsyncDebounce((value) => {
//     setGlobalFilter(value || undefined);
//   }, 200);

//   return (
//     <label className="flex gap-x-2 items-baseline">
//       <span className="text-gray-700">Search: </span>
//       <input
//         type="text"
//         className="rounded-md border-gray-300 shadow-sm focus:border-indigo-600 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
//         value={value || ''}
//         onChange={(e) => {
//           setValue(e.target.value);
//           onChange(e.target.value);
//         }}
//         placeholder={`${count} records...`}
//       />
//     </label>
//   );
// }

// This is a custom filter UI for selecting
// a unique option from a list
// export function SelectColumnFilter({
//   column: { filterValue, setFilter, preFilteredRows, id, render },
// }) {
//   // Calculate the options for filtering
//   // using the preFilteredRows
//   const options = React.useMemo(() => {
//     const options = new Set();
//     preFilteredRows.forEach((row) => {
//       options.add(row.values[id]);
//     });
//     return [...options.values()];
//   }, [id, preFilteredRows]);

//   // Render a multi-select box
//   return (
//     <label className="flex gap-x-2 items-baseline">
//       <span className="text-gray-700">{render('Header')}: </span>
//       <select
//         className="rounded-md border-gray-300 shadow-sm focus:border-indigo-600 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
//         name={id}
//         id={id}
//         value={filterValue}
//         onChange={(e) => {
//           setFilter(e.target.value || undefined);
//         }}
//       >
//         <option value="">All</option>
//         {options.map((option, i) => (
//           <option key={i} value={option as string}>
//             {option}
//           </option>
//         ))}
//       </select>
//     </label>
//   );
// }

interface TableProps<T extends Record<string, unknown>> {
  columns: Column<T>[];
  data: T[];
  loading: boolean;
  networkLoading: boolean;
  count: number;
  pageCount: number;
  pageSize: number;
  sortBy?: Array<{ id: string; desc: boolean }>;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  error?: ApolloError;
  fetchData: (
    pos: 'before' | 'after',
    sortBy: { id: string; desc?: boolean }
  ) => void;
  sortData: (rule: { id: string; desc?: boolean }) => void;
}
export const Table = <T extends Record<string, unknown>>({
  columns,
  data,
  loading,
  networkLoading,
  count,
  pageCount: controlledPageCount,
  pageSize: controlledPageSize,
  sortBy = [{ id: 'createdAt', desc: true }],
  fetchData,
  sortData,
  hasNextPage,
  hasPreviousPage,
  error,
}: TableProps<T>) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state,
  } = useTable<T>(
    {
      columns,
      data,
      initialState: {
        sortBy,
        pageIndex: 0,
        pageSize: controlledPageSize,
      },
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount,
      manualSortBy: true,
      disableSortBy: loading,
    },
    // useFilters,
    // useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    if (!state.sortBy?.[0]) {
      return;
    }
    if (
      state.sortBy[0]?.id !== sortBy?.[0]?.id ||
      state.sortBy[0]?.desc !== sortBy?.[0]?.desc
    ) {
      sortData(state.sortBy[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.sortBy?.[0], sortBy?.[0]]);

  const handlePaginate = useCallback(
    (pos: 'before' | 'after') => {
      fetchData(pos, state.sortBy?.[0] ?? sortBy[0]);
    },
    [fetchData, sortBy, state.sortBy]
  );

  if (error) {
    return (
      <div className="sm:rounded-lg bg-white divide-y divide-gray-200">
        <div className="text-center px-4 py-2">
          <Empty
            title="Oops! Something went wrong!"
            type="error"
            messages={error?.graphQLErrors.map(({ message }) => message)}
          />
        </div>
      </div>
    );
  }
  return (
    <>
      <div
        className={clsx('overflow-x-auto sm:rounded-lg', !loading && 'shadow')}
      >
        <div className="align-middle inline-block min-w-full">
          {/* <div className="sm:flex sm:gap-x-2">
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        {headerGroups.map((headerGroup) =>
          headerGroup.headers.map((column) =>
            column.Filter ? (
              <div className="mt-2 sm:mt-0" key={column.id}>
                {column.render('Filter')}
              </div>
            ) : null
          )
        )}
      </div> */}

          <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table
              {...getTableProps()}
              className="min-w-full divide-y divide-gray-200"
            >
              <thead className="bg-gray-100 select-none">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      // Add the sorting props to control sorting. For this example
                      // we can add them into the header props
                      <th
                        scope="col"
                        className="group px-4 py-3 text-left text-sm font-medium"
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        <div className="flex items-center justify-between">
                          {column.render('Header')}

                          {/* Add a sort direction indicator */}

                          <span
                            className={clsx(!column.canSort && 'invisible')}
                          >
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-4 h-4"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                >
                                  <path d="M0 0h24v24H0V0z" fill="none" />
                                  <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-4 h-4"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                >
                                  <path d="M0 0h24v24H0V0z" fill="none" />
                                  <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14l-6-6z" />
                                </svg>
                              )
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-4 h-4"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                              >
                                <path d="M0 0h24v24H0V0z" fill="none" />
                                <path d="M12 5.83L15.17 9l1.41-1.41L12 3 7.41 7.59 8.83 9 12 5.83zm0 12.34L8.83 15l-1.41 1.41L12 21l4.59-4.59L15.17 15 12 18.17z" />
                              </svg>
                            )}
                          </span>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              {!networkLoading ? (
                <tbody
                  {...getTableBodyProps()}
                  className="bg-white divide-y divide-gray-100"
                >
                  {page.map((row, i) => {
                    // new
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className="px-4 py-2 whitespace-nowrap"
                              role="cell"
                            >
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <tfoot className="divide-y divide-gray-200 animate-pulse">
                  {Array(5)
                    .fill(0)
                    .map((_, i) => (
                      <tr key={i}>
                        {columns.map((_, j) => {
                          return (
                            <td
                              key={j}
                              className="px-4 py-2 whitespace-nowrap"
                              role="cell"
                            >
                              <div className="h-5 bg-gray-300 rounded-sm"></div>
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                </tfoot>
              )}
            </table>
          </div>
        </div>
      </div>
      {/* Pagination */}
      {!loading && (
        <div
          className={clsx(
            'pt-4 px-4 sm:px-0 flex items-center justify-between',
            !hasPreviousPage && !hasNextPage && 'hidden'
          )}
        >
          <div className="flex-1 flex justify-between">
            <button
              type="button"
              onClick={() => handlePaginate('before')}
              disabled={!hasPreviousPage}
              className={clsx(
                'inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md bg-white select-none',
                !hasPreviousPage ? 'invisible' : 'hover:bg-gray-50 focus-lg'
              )}
            >
              Previous
            </button>

            {/* <span className="inline-flex items-center space-x-0.5 px-4 py-2 text-sm">
              <span>Page</span> <strong>{state.pageIndex + 1}</strong>
              <span>of</span>
              <strong>{pageOptions.length}</strong>
            </span> */}

            <button
              type="button"
              onClick={() => handlePaginate('after')}
              disabled={!hasNextPage}
              className={clsx(
                'inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md bg-white select-none',
                !hasNextPage ? 'invisible' : 'hover:bg-gray-50 focus-lg'
              )}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Table;
