import React from 'react';

export const ProjectsListItemLoading: React.FC = () => {
  return (
    <div className="relative rounded-md overflow-hidden bg-gray-300">
      <div>
        <div className="w-full h-40 bg-gray-300"></div>

        <div className="py-2 px-4 flex space-x-2 justify-between">
          <div className="space-y-0.5 truncate invisible">
            <span className="block truncate font-medium">Project name</span>
            <span className="block truncate text-sm text-gray-500">
              Media & date
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectsListItemLoading;
