import React from 'react';
import { useRouter } from 'next/router';
import { Layout, LayoutHeader } from '@typevid/ui-shared';
import { ProjectMediasCreate } from '../project/project-medias-create';
import { ProjectMenu } from '../project/project-menu';
import { useProject } from '../project/project.hooks';
import { MediasTable } from './medias-table';

export const Medias: React.FC = () => {
  const router = useRouter();

  const { data, loading, isCreateShown, setIsCreateShown } = useProject(true);

  return (
    <Layout
      title={`Medias - ${data?.name}`}
      header={
        <>
          <ProjectMenu
            activeTab="medias"
            projectId={router.query?.projectId as string}
          />
          <LayoutHeader title="Medias">
            <div className="flex items-center space-x-2">
              {loading || !data ? (
                <span className="w-full sm:w-auto bg-gray-200 border text-gray-200 border-transparent px-4 py-2 rounded-md animate-pulse text-sm font-medium">
                  New Media
                </span>
              ) : (
                <button
                  type="button"
                  className="inline-flex w-full sm:w-auto justify-center sm:justify-start items-center px-4 py-2 border border-transparent rounded-md text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 select-none focus:outline-none focus-visible:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => setIsCreateShown(true)}
                >
                  <svg
                    className="-ml-1 mr-2 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
                  </svg>
                  <span>New Media</span>
                </button>
              )}
            </div>
          </LayoutHeader>
        </>
      }
    >
      <div className="relative">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <div className="relative space-y-12 py-6">
            <MediasTable
              total={data?.totalMedias ?? 0}
              onCreateClick={() => setIsCreateShown(true)}
            />
          </div>
        </div>
        {data && (
          <ProjectMediasCreate
            shown={isCreateShown}
            projectId={data.id}
            defaultTitle={`Media #${(data.totalMedias ?? 0) + 1}`}
            onClose={() => setIsCreateShown(false)}
          />
        )}
      </div>
    </Layout>
  );
};

export default Medias;
