import React from 'react';

export interface ProjectsNewItemProps {
  id: string;
  name: string;
  imgUrl: string;
  description: string;
  category: string;
  onSelect: (id: string) => void;
}

export const ProjectsNewItem: React.FC<ProjectsNewItemProps> = ({
  id,
  name,
  imgUrl,
  description,
  category,
  onSelect,
}) => {
  return (
    <div className="block group bg-white shadow rounded-md overflow-hidden text-sm hover:outline outline-gray-300 select-none transition-outline duration-100">
      <div className="relative h-40">
        <div className="h-full w-full">
          <img src={imgUrl} alt={name} className="w-full h-full object-cover" />
        </div>
        <div className="flex flex-col justify-between absolute inset-0 p-4 bg-white opacity-0 group-hover:opacity-100 transition-opacity duration-75">
          <p>{description}</p>
          <button
            type="button"
            className="px-4 py-2 border border-transparent rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 select-none"
            onClick={() => onSelect(id)}
            tabIndex={-1}
          >
            Select
          </button>
        </div>
      </div>
      <div className="border-t px-4 py-2 border-gray-200">
        <div className="space-y-0.5 truncate">
          <span className="block truncate font-medium">{name}</span>
          <span className="block truncate  text-gray-500">{category}</span>
        </div>
      </div>
    </div>
  );
};

export default ProjectsNewItem;
