import React from 'react';
import { Layout, LayoutHeader } from '@typevid/ui-shared';
import { DashboardMenu } from '../dashboard/dashboard-menu';

export const WorkspaceSettings: React.FC = () => {
  return (
    <Layout
      title="Workspace Settings - Dashboard"
      header={<DashboardMenu activeTab="settings" />}
    >
      <LayoutHeader title="Workspace Settings" />
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <div className="space-y-12 py-6"></div>
      </div>
    </Layout>
  );
};

export default WorkspaceSettings;
