import React, { useCallback } from 'react';
import Link from 'next/link';
import { formatDate } from '@typevid/ui-shared';
import clsx from 'clsx';
import { Tooltip } from 'react-tippy';
import {
  Maybe,
  Mutation,
  MutationUpdateFavoriteProjectArgs,
  Project,
  Query,
  UpdateFavoriteProjectMutation,
  UserFavoriteProjectsQuery,
} from '@typevid/graphql';
import { useMutation } from '@apollo/client';
import { useAlert } from 'react-alert';

export const useOnToggleFavorite = () => {
  const alert = useAlert();

  const [updateFavoriteProject] = useMutation<
    { updateFavoriteProject: Mutation['updateFavoriteProject'] },
    MutationUpdateFavoriteProjectArgs
  >(UpdateFavoriteProjectMutation, {
    onError: (error) =>
      error.graphQLErrors.forEach(({ message }) => {
        alert.error(
          <>
            <h4 className="text-sm font-medium">An error has occurred!</h4>
            <p className="text-xs">{message}</p>
          </>,
          { timeout: 0, position: 'top right' }
        );
      }),

    update(cache, { data }) {
      if (!data) return;
      const userFavoriteProjectsData = cache.readQuery<{
        userFavoriteProjects: Query['userFavoriteProjects'];
      }>({
        query: UserFavoriteProjectsQuery,
      });

      if (userFavoriteProjectsData?.userFavoriteProjects) {
        let d = userFavoriteProjectsData.userFavoriteProjects;
        if (data.updateFavoriteProject.favorite) {
          // append
          d = [data.updateFavoriteProject, ...d];
        } else {
          //delete
          d = d.filter(({ id }) => id !== data.updateFavoriteProject.id);
        }

        cache.writeQuery<{
          userFavoriteProjects: Query['userFavoriteProjects'];
        }>({
          query: UserFavoriteProjectsQuery,
          data: {
            userFavoriteProjects: d,
          },
        });
      }
    },
  });

  const onToggleFavorite = useCallback(
    (e: React.MouseEvent, id: string, favorite: boolean, project: Project) => {
      e.preventDefault();

      updateFavoriteProject({
        variables: { id, favorite: !favorite },
        optimisticResponse: {
          updateFavoriteProject: {
            ...project,
            favorite: !favorite,
          },
        },
      });
    },
    [updateFavoriteProject]
  );

  return onToggleFavorite;
};

export interface ProjectsListItemProps {
  id: string;
  name: string;
  scope: string | null | undefined;
  templateId: string;
  count?: Maybe<number>;
  date: string;
  datePrefix?: string;
  favorite: boolean;
  onFavoriteClick: (e: React.MouseEvent, id: string, favorite: boolean) => void;
}

export const ProjectsListItem: React.FC<ProjectsListItemProps> = ({
  id,
  name,
  scope,
  templateId,
  count = 0,
  date,
  favorite = false,
  datePrefix = 'Created',
  onFavoriteClick,
}) => {
  return (
    <div className="block relative group text-sm bg-white shadow hover:outline outline-gray-300 rounded-md select-none transition-outline duration-100">
      <Link href={`/${scope}/${id}`}>
        <a
          href={`/${scope}/${id}`}
          className="block rounded-md overflow-hidden focus-visible:outline focus-visible:outline-2 focus-visible:outline-indigo-500"
        >
          <div className="w-full h-40 bg-gray-300"></div>

          <div className="py-2 px-4 flex space-x-2 justify-between">
            <div className="space-y-0.5 truncate">
              <span className="block truncate font-medium">{name}</span>
              <span className="block truncate  text-gray-500">
                {count} {count !== 1 ? 'Medias' : 'Media'} - {datePrefix}{' '}
                {formatDate(date, 'D')}
              </span>
            </div>
            <div className="inline-flex items-center space-x-1">
              <Tooltip
                size="small"
                position="top"
                arrow={true}
                duration={150}
                title={favorite ? 'Remove from favorites' : 'Add to favorites'}
                className="!inline-flex"
              >
                <button
                  type="button"
                  className={clsx(
                    'p-1 rounded-sm  opacity-0 group-hover:opacity-100 hover:bg-gray-100 items-center justify-center focus:outline-none transition-opacity duration-100',
                    favorite ? 'text-yellow-500' : 'text-gray-300'
                  )}
                  onClick={(e) => onFavoriteClick(e, id, favorite)}
                  tabIndex={-1}
                >
                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                </button>
              </Tooltip>
            </div>
          </div>
        </a>
      </Link>
      <Link href={`/${scope}/designer/${templateId}`}>
        <a
          href={`/${scope}/designer/${templateId}`}
          className="absolute top-2 right-2 opacity-0 group-focus-within:opacity-100 group-hover:opacity-100 inline-flex items-center px-4 py-2 rounded-md text-sm leading-none bg-black/30 hover:bg-black/50 text-white select-none focus:outline-none focus-visible:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-opacity duration-100"
        >
          <svg
            className="-ml-1 mr-2 h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            aria-hidden="true"
          >
            <path d="M16.24,11.51l1.57-1.57l-3.75-3.75l-1.57,1.57L8.35,3.63c-0.78-0.78-2.05-0.78-2.83,0l-1.9,1.9 c-0.78,0.78-0.78,2.05,0,2.83l4.13,4.13L3,17.25V21h3.75l4.76-4.76l4.13,4.13c0.95,0.95,2.23,0.6,2.83,0l1.9-1.9 c0.78-0.78,0.78-2.05,0-2.83L16.24,11.51z M9.18,11.07L5.04,6.94l1.89-1.9c0,0,0,0,0,0l1.27,1.27L7.02,7.5l1.41,1.41l1.19-1.19 l1.45,1.45L9.18,11.07z M17.06,18.96l-4.13-4.13l1.9-1.9l1.45,1.45l-1.19,1.19l1.41,1.41l1.19-1.19l1.27,1.27L17.06,18.96z" />
            <path d="M20.71,7.04c0.39-0.39,0.39-1.02,0-1.41l-2.34-2.34c-0.47-0.47-1.12-0.29-1.41,0l-1.83,1.83l3.75,3.75L20.71,7.04z" />
          </svg>
          Open Designer
        </a>
      </Link>
    </div>
  );
};

export default ProjectsListItem;
