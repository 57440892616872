import React from 'react';
import Link from 'next/link';
import { LayoutHeader } from '@typevid/ui-shared';
import { Project } from '@typevid/graphql';
import { Tooltip } from 'react-tippy';
import clsx from 'clsx';
import { useOnToggleFavorite } from '../projects/projects-list-item';

export interface ProjectHeaderProps {
  loading: boolean;
  scope: string | null | undefined;
  data?: Project | null;
  onCreate: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const ProjectHeader: React.FC<ProjectHeaderProps> = ({
  loading,
  data,
  scope,
  onCreate,
}) => {
  const onToggleFavorite = useOnToggleFavorite();

  return (
    <LayoutHeader loading={loading} title={data?.name ?? ''}>
      <div className="flex items-center space-x-2">
        {loading || !data ? (
          <>
            <span className="bg-gray-200 text-gray-200 border border-transparent px-2 py-2 rounded-md animate-pulse text-sm font-medium">
              AA
            </span>
            <span className="bg-gray-200 text-gray-200 border border-transparent px-4 py-2 rounded-md animate-pulse text-sm font-medium">
              Open Designer
            </span>
            <span className="bg-gray-200 border text-gray-200 border-transparent px-4 py-2 rounded-md animate-pulse text-sm font-medium">
              New Media
            </span>
          </>
        ) : (
          <>
            <Tooltip
              size="small"
              position="top"
              arrow={true}
              duration={150}
              title={
                data.favorite ? 'Remove from favorites' : 'Add to favorites'
              }
              className="!inline-flex"
            >
              <button
                type="button"
                className={clsx(
                  'inline-flex items-center px-2 py-2 border border-gray-200 rounded-md text-sm font-medium bg-white hover:bg-gray-50 select-none focus:outline-none focus-visible:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                  data.favorite ? 'text-yellow-500' : 'text-gray-700'
                )}
                onClick={(e) =>
                  onToggleFavorite(e, data.id, data.favorite, data)
                }
              >
                <svg
                  className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                </svg>
              </button>
            </Tooltip>
            <Link href={`/${scope}/designer/${data?.templateId}`}>
              <a
                href={`/${scope}/designer/${data?.templateId}`}
                className="inline-flex items-center px-4 py-2 border border-gray-200 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 select-none focus:outline-none focus-visible:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <svg
                  className="-ml-1 mr-2 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M16.24,11.51l1.57-1.57l-3.75-3.75l-1.57,1.57L8.35,3.63c-0.78-0.78-2.05-0.78-2.83,0l-1.9,1.9 c-0.78,0.78-0.78,2.05,0,2.83l4.13,4.13L3,17.25V21h3.75l4.76-4.76l4.13,4.13c0.95,0.95,2.23,0.6,2.83,0l1.9-1.9 c0.78-0.78,0.78-2.05,0-2.83L16.24,11.51z M9.18,11.07L5.04,6.94l1.89-1.9c0,0,0,0,0,0l1.27,1.27L7.02,7.5l1.41,1.41l1.19-1.19 l1.45,1.45L9.18,11.07z M17.06,18.96l-4.13-4.13l1.9-1.9l1.45,1.45l-1.19,1.19l1.41,1.41l1.19-1.19l1.27,1.27L17.06,18.96z" />
                  <path d="M20.71,7.04c0.39-0.39,0.39-1.02,0-1.41l-2.34-2.34c-0.47-0.47-1.12-0.29-1.41,0l-1.83,1.83l3.75,3.75L20.71,7.04z" />
                </svg>
                <span>Open Designer</span>
              </a>
            </Link>
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 select-none focus:outline-none focus-visible:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={onCreate}
            >
              <svg
                className="-ml-1 mr-2 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
              </svg>
              <span>New Media</span>
            </button>
          </>
        )}
      </div>
    </LayoutHeader>
  );
};

export default ProjectHeader;
