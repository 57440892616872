import React from 'react';

import {
  Query,
  QueryRecentExportsArgs,
  RecentExportsQuery,
} from '@typevid/graphql';
import { NetworkStatus, useQuery } from '@apollo/client';
import { ProjectExports } from '../project/project-exports';

export const DashboardExports: React.FC = () => {
  const { loading, data, error, networkStatus, refetch } = useQuery<
    {
      recentExports: Query['recentExports'];
    },
    QueryRecentExportsArgs
  >(RecentExportsQuery, {
    pollInterval: 60000,
    notifyOnNetworkStatusChange: true,
  });

  return (
    <ProjectExports
      loading={loading}
      networkLoading={networkStatus === NetworkStatus.loading}
      data={data}
      error={error}
      refetch={refetch}
      source="dashboard"
    />
  );
};

export default DashboardExports;
