import { Listbox, Popover, Tab, Transition } from '@headlessui/react';
import {
  convertUnit,
  defaultFormats,
  defaultFormatsGroups,
  FormatPill,
  FormatPillBtn,
  FormatPillProps,
  useDebouncy,
} from '@typevid/ui-shared';
import clsx from 'clsx';
import { merge } from 'object-path-immutable';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

interface ProjectsFormatsPanelProps {
  groupId: string;
  formats: FormatPillProps[];
  onSelect: (item: FormatPillProps) => void;
}
const ProjectsFormatsPanel: React.FC<ProjectsFormatsPanelProps> = React.memo(
  ({ groupId, formats, onSelect }) => {
    const handleOnClick = useCallback(
      (
        e: React.MouseEvent,
        { id, name, sizeLabel, width, height, orientation }: FormatPillProps
      ) => {
        e.preventDefault();
        e.stopPropagation();
        onSelect({ id, name, sizeLabel, width, height, orientation });
      },
      [onSelect]
    );

    const data = useMemo(
      () =>
        defaultFormats.filter(
          ({ groupId: _groupId, id: _id }) =>
            _groupId === groupId &&
            formats.findIndex(({ id }) => id === _id) === -1
        ),
      [groupId, formats]
    );
    return (
      <Tab.Panel
        key={groupId}
        className="grid sm:grid-cols-2 gap-2 p-4 focus:outline-none"
      >
        {data.map((item) => (
          <FormatPillBtn
            {...item}
            key={item.id}
            tabIndex={-1}
            onClick={handleOnClick}
          />
        ))}
      </Tab.Panel>
    );
  }
);

const CUSTOM_VALIDATION = {
  px: { minMax: [40, 8000], maxArea: 25000000 }, // maxarea = 5000 *5000
  cm: { minMax: [1.058, 211.667], maxArea: 17501.173 },
  mm: { minMax: [10.583, 2116.667], maxArea: 1750109.389 },
  in: { minMax: [0.417, 83.333], maxArea: 2712.639 },
};
interface ProjectsFormatsCustomPanelProps {
  onAdd: (item: FormatPillProps) => void;
  active: boolean;
  formats: FormatPillProps[];
}
const ProjectsFormatsCustomPanel: React.FC<ProjectsFormatsCustomPanelProps> =
  React.memo(({ formats, active, onAdd }) => {
    const [
      { name, width, height, unit, isValid, error, errorInput, focusedInput },
      setState,
    ] = useState<{
      name: string;
      height: string;
      width: string;
      unit: 'px' | 'cm' | 'mm' | 'in';
      isValid: { dims: boolean; name: boolean };
      error: { dims: React.ReactNode; name: React.ReactNode };
      errorInput: string[] | null;
      focusedInput: 'width' | 'height' | null;
    }>({
      name: 'Custom Format',
      width: '',
      height: '',
      unit: 'px',
      isValid: { dims: false, name: true },
      error: { dims: null, name: null },
      errorInput: null,
      focusedInput: null,
    });

    const handleOnUnitChange = useCallback(
      (_unit: 'px' | 'cm' | 'mm' | 'in') => {
        if (_unit === unit) {
          setState((s) => ({ ...s, unit: _unit }));
          return;
        }
        setState((s) => ({
          ...s,
          width: !isNaN(parseFloat(width))
            ? `${convertUnit(width, unit, _unit)}`
            : width,
          height: !isNaN(parseFloat(height))
            ? `${convertUnit(height, unit, _unit)}`
            : height,
          unit: _unit,
        }));
      },
      [height, unit, width]
    );

    const findFormat = useCallback(() => {
      // validate unique format
      const widthPx = convertUnit(width, unit, 'px');
      const heightPx = convertUnit(height, unit, 'px');
      const found = formats.find(
        ({ width: w, height: h }) =>
          Math.abs(w - widthPx) < 0.5 && Math.abs(h - heightPx) < 0.5
      );
      return found;
    }, [height, formats, unit, width]);

    const handleOnAdd = useCallback(
      async (e?: React.MouseEvent) => {
        e?.preventDefault();
        // wait for state update after validation
        await new Promise((resolve) => setTimeout(resolve, 100));
        if (!isValid.dims || !isValid.name) return;

        const found = findFormat();
        if (found) {
          setState((s) => ({
            ...s,
            isValid: { ...s.isValid, dims: false },
            errorInput: ['height', 'width'],
            error: {
              ...s.error,
              dims: (
                <span>
                  A format with similar dimensions already selected:{' '}
                  <b>
                    {found.name} ({found.sizeLabel})
                  </b>
                </span>
              ),
            },
          }));
          return;
        }

        const pxWidth = convertUnit(width, unit, 'px');
        const pxHeight = convertUnit(height, unit, 'px');

        const item: FormatPillProps = {
          id: `${width}x${height}${unit}`,
          name: name.trim(),
          sizeLabel: `${width} x ${height} ${unit}`,
          width: pxWidth,
          height: pxHeight,
          orientation: pxWidth / pxHeight <= 1 ? 1 : 0,
        };

        onAdd(item);
        setState((s) => ({
          ...s,
          width: '',
          height: '',
          isValid: { ...s.isValid, dims: false },
          errorInput: null,
          error: { ...s.error, dims: null },
        }));
      },
      [height, findFormat, isValid, name, onAdd, unit, width]
    );

    const handleOnFix = useCallback(
      (key: 'width' | 'height', value: string) => {
        setState((s) => ({ ...s, [key]: value }));
      },
      []
    );

    useDebouncy(
      () => {
        const _name = name.trim();
        if (_name.length < 1) {
          setState((s) => ({
            ...s,
            isValid: { ...s.isValid, name: false },
            error: { ...s.error, name: `Enter a format name.` },
          }));
          return;
        }
        if (_name.length > 40) {
          setState((s) => ({
            ...s,
            isValid: { ...s.isValid, name: false },
            error: {
              ...s.error,
              name: `Name must be less than 40 characters.`,
            },
          }));
          return;
        }
        setState((s) => ({
          ...s,
          isValid: { ...s.isValid, name: true },
          error: { ...s.error, name: null },
        }));
      },
      100,
      [name]
    );

    useDebouncy(
      () => {
        if (!CUSTOM_VALIDATION[unit]) return;
        if (width === '' || height === '') return;
        const w =
          Math.round((parseFloat(width) + Number.EPSILON) * 1000) / 1000;
        const h =
          Math.round((parseFloat(height) + Number.EPSILON) * 1000) / 1000;

        if (isNaN(w) || isNaN(h)) return;

        // validate min max
        const isValidWidth =
          w <= CUSTOM_VALIDATION[unit].minMax[1] &&
          w >= CUSTOM_VALIDATION[unit].minMax[0];
        const isValidHeight =
          h <= CUSTOM_VALIDATION[unit].minMax[1] &&
          h >= CUSTOM_VALIDATION[unit].minMax[0];

        if (!isValidWidth || !isValidHeight) {
          setState((s) => ({
            ...s,
            width: `${w}`,
            height: `${h}`,
            isValid: { ...s.isValid, dims: false },
            error: {
              ...s.error,
              dims: `Dimensions must be at least ${CUSTOM_VALIDATION[unit].minMax[0]}${unit} and no more than ${CUSTOM_VALIDATION[unit].minMax[1]}${unit}.`,
            },
            errorInput:
              !isValidWidth && !isValidHeight
                ? ['width', 'height']
                : !isValidHeight
                ? ['height']
                : ['width'],
          }));
          return;
        }

        //  validate allowed area
        if (w * h > CUSTOM_VALIDATION[unit].maxArea) {
          let fix =
            Math.floor(
              (CUSTOM_VALIDATION[unit].maxArea / h + Number.EPSILON) * 1000
            ) / 1000;

          if (focusedInput === 'height') {
            fix =
              Math.floor(
                (CUSTOM_VALIDATION[unit].maxArea / w + Number.EPSILON) * 1000
              ) / 1000;
          }
          setState((s) => ({
            ...s,
            width: `${w}`,
            height: `${h}`,
            errorInput: ['height', 'width'],
            isValid: { ...s.isValid, dims: false },
            error: {
              ...s.error,
              dims: (
                <span>
                  Must be less than {fix}
                  {unit} to stay within our maximum allowed area.{' '}
                  <button
                    type="button"
                    onClick={() =>
                      focusedInput ? handleOnFix(focusedInput, `${fix}`) : null
                    }
                    className="underline rounded-md hover:text-red-700 focus:outline-none focus:ring-indigo-600 focus-visible:ring-1"
                  >
                    Fix it for me
                  </button>
                  .
                </span>
              ),
            },
          }));
          return;
        }

        setState((s) => ({
          ...s,
          width: `${w}`,
          height: `${h}`,
          isValid: { ...s.isValid, dims: true },
          errorInput: null,
          error: { ...s.error, dims: null },
        }));
      },
      100,
      [width, height, unit, focusedInput]
    );

    useHotkeys(
      'enter',
      (e) => {
        handleOnAdd();
      },
      { enabled: active, enableOnTags: ['INPUT'] },
      [active]
    );

    return (
      <Tab.Panel className="p-4 min-h-[15rem] focus:outline-none">
        <div className="flex space-x-2">
          <div className="w-4/5">
            <div className="mb-1 flex">
              <input
                className={clsx(
                  'text-sm focus:outline-none  w-full p-2 block rounded-md border',
                  error.name
                    ? 'border-red-500'
                    : 'border-gray-300 focus:ring-indigo-600 focus:border-indigo-600'
                )}
                name="name"
                id="name"
                autoComplete="off"
                autoCorrect="off"
                value={name}
                onChange={(e) =>
                  setState((s) => ({
                    ...s,
                    name: e.target.value,
                  }))
                }
              />
            </div>
            <label
              htmlFor="name"
              className="block text-sm text-center text-gray-500"
            >
              Name
            </label>
          </div>
          <div>
            <div className="mb-1 flex">
              <input
                className={clsx(
                  'hide-arrows text-sm focus:outline-none  w-full p-2 block rounded-md border',
                  errorInput?.includes('width')
                    ? 'border-red-500'
                    : 'border-gray-300 focus:ring-indigo-600 focus:border-indigo-600'
                )}
                type="number"
                name="width"
                id="width"
                value={width}
                step="any"
                pattern="[\.\d]*"
                inputMode="decimal"
                autoComplete="off"
                autoCorrect="off"
                onFocus={() =>
                  setState((s) => ({ ...s, focusedInput: 'width' }))
                }
                onChange={(e) =>
                  setState((s) => ({
                    ...s,
                    width: e.target.value,
                  }))
                }
              />
            </div>
            <label
              htmlFor="width"
              className="block text-sm text-center text-gray-500"
            >
              Width
            </label>
          </div>
          <div>
            <div className="mb-1 flex">
              <input
                className={clsx(
                  'hide-arrows text-sm focus:outline-none  w-full p-2 block rounded-md border',
                  errorInput?.includes('height')
                    ? 'border-red-500'
                    : 'border-gray-300 focus:ring-indigo-600 focus:border-indigo-600'
                )}
                type="number"
                name="height"
                id="height"
                value={height}
                step="any"
                pattern="[\.\d]*"
                inputMode="decimal"
                autoComplete="off"
                autoCorrect="off"
                onFocus={() =>
                  setState((s) => ({ ...s, focusedInput: 'height' }))
                }
                onChange={(e) =>
                  setState((s) => ({
                    ...s,
                    height: e.target.value,
                  }))
                }
              />
            </div>
            <label
              htmlFor="height"
              className="block text-sm text-center text-gray-500"
            >
              Height
            </label>
          </div>
          <div>
            <Listbox value={unit} onChange={handleOnUnitChange}>
              <div className="relative ">
                <Listbox.Button className="relative text-sm py-2 pl-2 w-16 text-left block rounded-md bg-white border border-gray-300 focus:outline-none focus:ring-indigo-600 focus:border-indigo-600">
                  <span className="block truncate">{unit}</span>
                  <span className="absolute inset-y-0 right-0 flex items-center pointer-events-none pr-2">
                    <svg
                      className="h-5 w-5 text-gray-400"
                      viewBox="0 0 20 20"
                      fill="none"
                      stroke="currentColor"
                    >
                      <path
                        d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-sm bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {['px', 'in', 'mm', 'cm'].map((unitVal) => (
                      <Listbox.Option
                        key={unitVal}
                        className={({ active }) =>
                          `select-none relative py-2 pl-2 pr-4 ${
                            active ? 'bg-gray-100' : ''
                          }`
                        }
                        value={unitVal}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? 'font-medium' : 'font-normal'
                              }`}
                            >
                              {unitVal}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 right-0 flex items-center px-2">
                                <svg
                                  className="h-5 w-5"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
        </div>
        {(Object.keys(error) as Array<keyof typeof error>).map((key, i) =>
          error[key] ? (
            <div
              key={i}
              className="mt-2 text-sm p-2 pl-3 rounded-[0.25rem] text-red-600 bg-gray-50 border-l-4 border-red-500"
            >
              <p>{error[key]}</p>
            </div>
          ) : null
        )}

        <div className="w-full mt-4">
          <button
            type="button"
            className={clsx(
              'inline-flex justify-center items-center w-full py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600',
              !isValid.dims || !isValid.name
                ? 'opacity-50 cursor-not-allowed'
                : 'hover:bg-indigo-700'
            )}
            disabled={!isValid.dims || !isValid.name}
            onClick={handleOnAdd}
          >
            Add Format
          </button>
        </div>
      </Tab.Panel>
    );
  });

interface ProjectsFormatsSelectorProps {
  formats: FormatPillProps[];
  placeHolder: string;
  error?: string | null;
  disabled: boolean;
  onChange: (formats: FormatPillProps[]) => void;
}
export const ProjectsFormatsSelector: React.FC<
  ProjectsFormatsSelectorProps
> = ({ formats, placeHolder, disabled, error, onChange }) => {
  const [open, setOpen] = useState(false);

  const handleOnSelect = useCallback(
    (item: FormatPillProps) => {
      if (formats.findIndex(({ id }) => id === item.id) === -1) {
        onChange([...formats, { ...item, isBase: formats.length === 0 }]);
      }
    },
    [formats, onChange]
  );

  const handleOnRemove = useCallback(
    (item: FormatPillProps) => {
      const f = formats.filter(({ id }) => id !== item.id);
      if (item.isBase && f.length > 0) {
        // make the first one as base
        onChange(merge(f, '0', { isBase: true }));
      } else {
        onChange(f);
      }
    },
    [formats, onChange]
  );

  useHotkeys(
    'escape',
    () => {
      setOpen(false);
    },
    { enabled: open },
    [open]
  );

  useEffect(() => {
    if (disabled) {
      setOpen(false);
    }
  }, [disabled]);

  return (
    <div className="w-full relative">
      <Popover className="relative">
        <>
          <div
            className={clsx(
              'relative flex flex-1 items-start justify-start w-full overflow-hidden rounded-md sm:text-sm border',
              error ? 'border-red-500' : 'border-gray-300'
            )}
          >
            <button
              type="button"
              className={clsx(
                'm-1 w-9 h-7 inline-flex items-center justify-center rounded-md bg-gray-200 border border-transparent',
                disabled
                  ? 'opacity-30 cursor-not-allowed'
                  : ' hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring-indigo-600 focus-visible:border-indigo-600'
              )}
              onClick={() => setOpen((s) => !s)}
              disabled={disabled}
            >
              <svg
                className={clsx(
                  'w-5 h-5 transition-transform duration-100',
                  open && 'transform rotate-45'
                )}
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M0 0h24v24H0z" fill="none"></path>
                <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path>
              </svg>
              <span className="sr-only">
                {open ? 'Close Formats Popover' : 'Open Formats Popover'}
              </span>
            </button>
            {formats.length === 0 ? (
              <div
                onClick={() => setOpen((s) => !s)}
                className="absolute left-11 top-1/2 right-0 transform -translate-y-1/2 text-sm text-gray-400 select-none"
              >
                {placeHolder}
              </div>
            ) : (
              <div
                onClick={() => (!disabled ? setOpen((s) => !s) : null)}
                className="flex flex-col-reverse flex-1 max-h-[6rem] overflow-auto"
              >
                <div className="flex flex-wrap gap-1 py-1">
                  {formats.map((item) => (
                    <div
                      key={item.id}
                      className="inline-flex rounded-md text-left border border-gray-200"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <FormatPill {...item} onRemove={handleOnRemove} />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          <Transition
            as={Fragment}
            unmount={false}
            show={open}
            enter="transition ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Popover.Panel
              static={true}
              className="absolute z-10 w-full mt-1 transform -translate-x-1/2 left-1/2"
            >
              <div className="overflow-hidden rounded-md ring-1 ring-opacity-5 ring-black shadow-lg">
                <div className="relative bg-white text-sm">
                  <Tab.Group defaultIndex={1}>
                    <Tab.List className="w-full overflow-x-scroll shadow no-scrollbar">
                      <div className="flex space-x-2 w-max ">
                        <Tab
                          key="custom"
                          className={({ selected }) =>
                            clsx(
                              'px-2 py-2 border-t-2 border-b-2 border-transparent focus:outline-none focus-visible:border-b-indigo-600',
                              selected
                                ? 'border-b-gray-700'
                                : 'text-gray-500 hover:text-gray-700'
                            )
                          }
                        >
                          Custom
                        </Tab>
                        {defaultFormatsGroups.map(({ id, name }) => (
                          <Tab
                            key={id}
                            className={({ selected }) =>
                              clsx(
                                'px-2 py-2 border-t-2 border-b-2 border-transparent focus:outline-none focus-visible:border-b-indigo-600',
                                selected
                                  ? 'border-b-gray-700'
                                  : 'text-gray-500 hover:text-gray-700'
                              )
                            }
                          >
                            {name}
                          </Tab>
                        ))}
                      </div>
                    </Tab.List>

                    <Tab.Panels className="max-h-[15rem] overflow-y-auto">
                      {({ selectedIndex }) => (
                        <>
                          <ProjectsFormatsCustomPanel
                            formats={formats}
                            onAdd={handleOnSelect}
                            active={selectedIndex === 0 && open}
                          />
                          {defaultFormatsGroups.map(({ id }) => (
                            <ProjectsFormatsPanel
                              key={id}
                              groupId={id}
                              formats={formats}
                              onSelect={handleOnSelect}
                            />
                          ))}
                        </>
                      )}
                    </Tab.Panels>
                  </Tab.Group>

                  <div className="py-2 px-4 bg-gray-50 text-right">
                    <button
                      type="button"
                      className={clsx(
                        'inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 select-none focus:outline-none focus-visible:ring-2 focus:ring-offset-2 focus:ring-indigo-600'
                      )}
                      onClick={() => setOpen(false)}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
          {!!error && (
            <div className="mt-2 text-sm p-2 pl-3 rounded-[0.25rem] text-red-600 bg-gray-50 border-l-4 border-red-500">
              <p>{error}</p>
            </div>
          )}
        </>
      </Popover>
    </div>
  );
};
