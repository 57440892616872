import { useAuth, useSetBreadcrumbState } from '@typevid/ui-shared';
import clsx from 'clsx';
import Link from 'next/link';
import React, { useEffect } from 'react';

const DASHBOARD_TABS = [
  { id: 'dashboard', label: 'Overview', path: '' },
  { id: 'projects', label: 'Projects', path: '/projects' },
  { id: 'settings', label: 'Settings', path: '/settings' },
];

interface DashboardMenuProps {
  activeTab: string;
}
export const DashboardMenu: React.FC<DashboardMenuProps> = ({ activeTab }) => {
  const setBreadcrumbState = useSetBreadcrumbState();
  const { scope } = useAuth();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setBreadcrumbState(() => ({
      show: true,
      data: { 0: { href: `/${scope}`, label: 'Dashboard' } },
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="bg-white relative select-none">
      <div className="relative z-10 px-0 sm:px-2 lg:px-4">
        <nav className="flex items-center text-sm h-12">
          {DASHBOARD_TABS.map(({ id, label, path }) => (
            <div key={id} className="relative">
              <Link href={`/${scope}${path}`}>
                <a
                  href={`/${scope}${path}`}
                  className={clsx(
                    'inline-flex px-3 py-3 rounded-md leading-none transition-colors focus-md',
                    activeTab === id
                      ? 'text-gray-900'
                      : 'text-gray-500 hover:bg-gray-100 hover:text-gray-900'
                  )}
                >
                  <span className="relative px-1">
                    {label}
                    {activeTab === id && (
                      <span className="absolute inset-x-0 -bottom-[1.075rem] h-0.5 bg-gray-700" />
                    )}
                  </span>
                </a>
              </Link>
            </div>
          ))}
        </nav>
      </div>
      <span className="absolute bottom-0 inset-x-0 h-px bg-gray-100" />
    </div>
  );
};

export default DashboardMenu;
