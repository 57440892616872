import { ApolloError, ApolloQueryResult } from '@apollo/client';
import { Transition } from '@headlessui/react';
import {
  Maybe,
  MediaExportStatusType,
  MediaModeType,
  Query,
  QueryRecentExportsArgs,
} from '@typevid/graphql';
import {
  EditorStatus,
  transitionProps,
  Empty,
  useAuth,
  formatDate,
} from '@typevid/ui-shared';
import clsx from 'clsx';
import filesize from 'filesize';
import Link from 'next/link';
import React, { useMemo } from 'react';
import { Tooltip } from 'react-tippy';
import { ExportAvatar } from '../export/export-header';

interface ExportItemProps {
  createdAt: string;
  id: string;
  scope: string | null | undefined;
  fileType?: Maybe<string>;
  status: MediaExportStatusType;
  format?: Maybe<string>;
  url?: Maybe<string>;
  size?: Maybe<string>;
  media: { id: string; title: string };
  project: { id: string; name: string };
  showProject?: boolean;
  loading?: boolean;
}
const ExportItem: React.FC<ExportItemProps> = React.memo(
  ({
    id,
    scope,
    fileType,
    createdAt,
    format,
    media,
    project,
    status,
    url,
    size,
    showProject,
    loading,
  }) => {
    const inData = useMemo(() => {
      if (showProject) {
        return {
          link: `/${scope}/${project?.id}`,
          title: project?.name ?? '',
        };
      }
      return {
        link: `/${scope}/${project?.id}/${media?.id}`,
        title: media?.title ?? '',
      };
    }, [
      media?.id,
      media?.title,
      project?.id,
      project?.name,
      scope,
      showProject,
    ]);

    return (
      <div className="flex  group relative text-sm bg-white shadow-sm rounded-md overflow-hidden select-none transition-outline duration-100">
        <Link href={`/${scope}/${project?.id}/${media?.id}/${id}`}>
          <a
            href={`/${scope}/${project?.id}/${media?.id}/${id}`}
            tabIndex={-1}
            className="inline-flex items-stretch justify-items-stretch flex-shrink-0 w-1/5"
          >
            <ExportAvatar fileType={fileType} />
          </a>
        </Link>
        <div className="w-4/5 py-4 px-4 space-y-0.5">
          <div className="flex items-center max-w-full space-x-2">
            <Link href={`/${scope}/${project?.id}/${media?.id}/${id}`}>
              <a
                href={`/${scope}/${project?.id}/${media?.id}/${id}`}
                className="block truncate font-medium hover:text-indigo-500 focus-visible:text-indigo-500 focus:outline-none focus-visible:underline"
              >
                {format}
              </a>
            </Link>

            <div className="inline-flex shrink-0">
              <EditorStatus
                status={!loading ? status : ('···' as MediaExportStatusType)}
              />
            </div>
          </div>

          <div className="flex items-center max-w-full text-gray-500">
            <div className="truncate">
              In{' '}
              <Link href={inData.link}>
                <a
                  href={inData.link}
                  className="hover:text-gray-700 focus-visible:text-indigo-500 focus:outline-none focus-visible:underline"
                >
                  {inData.title}
                </a>
              </Link>
            </div>

            <div className="flex-shrink-0">
              &nbsp;- Created{' '}
              {loading ? <span>···</span> : formatDate(createdAt, 'D')}
            </div>
          </div>
        </div>
        {status === MediaExportStatusType.Exported && !!url && (
          <div className="absolute right-4 top-1/2 -translate-y-1/2 w-8 h-8 opacity-0 group-hover:opacity-100 transition-opacity">
            <Tooltip
              className="w-full h-full"
              size="small"
              position="top"
              arrow={true}
              duration={150}
              title={`Download (${
                size ? filesize(parseFloat(size), { round: 2 }) : '-'
              })`}
            >
              <a
                href={url}
                download={true}
                tabIndex={-1}
                className="flex items-center justify-center w-full h-full rounded-md bg-white hover:bg-gray-100"
              >
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" />
                </svg>
              </a>
            </Tooltip>
          </div>
        )}
      </div>
    );
  }
);

const Loading: React.FC = React.memo(() => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 animate-pulse">
      {Array(9)
        .fill(0)
        .map((_, i) => (
          <div key={i} className="bg-gray-300 rounded-md overflow-hidden">
            <div className="py-4 px-4 space-y-0.5 invisible">
              <div className="inline-flex items-center justify-between max-w-full space-x-2">
                <div className="truncate font-medium">Title</div>
                <EditorStatus status={MediaModeType.Edit} />
              </div>

              <div className="text-sm truncate text-gray-500 invisible">
                <span>Project & date</span>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
});

export interface ProjectExportsProps {
  title?: string;
  source?: 'dashboard' | 'project';
  loading: boolean;
  networkLoading: boolean;
  error: ApolloError | undefined;
  data:
    | {
        recentExports: Query['recentExports'];
      }
    | undefined;

  refetch: (variables?: Partial<QueryRecentExportsArgs> | undefined) => Promise<
    ApolloQueryResult<{
      recentExports: Query['recentExports'];
    }>
  >;
}

const ProjectExportsComponent: React.FC<ProjectExportsProps> = ({
  title = 'Recent Exports',
  source = 'project',
  data,
  error,
  loading,
  networkLoading,
  refetch,
}) => {
  const { scope } = useAuth();
  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center space-x-4">
        <h3 className="leading-none font-medium text-base">{title}</h3>
        <span className="h-px flex-1 bg-gray-300" />

        <div>
          <Tooltip
            size="small"
            position="top"
            disabled={loading}
            arrow={true}
            duration={150}
            html={
              <span>
                Refresh
                <span className="block text-xs">
                  (Auto-refreshes every 60 seconds)
                </span>
              </span>
            }
          >
            <button
              className={clsx(
                'flex justify-center items-center px-2 py-1 rounded-md bg-white border border-gray-300 select-none focus:outline-none focus-visible:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                loading
                  ? 'bg-gray-300 text-gray-400'
                  : 'hover:bg-gray-50 text-gray-500 focus-lg'
              )}
              disabled={loading}
              onClick={() => refetch()}
            >
              <svg
                className={clsx('h-5 w-5', loading && 'animate-spin')}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M12 4V1L8 5l4 4V6c3.31 0 6 2.69 6 6 0 1.01-.25 1.97-.7 2.8l1.46 1.46C19.54 15.03 20 13.57 20 12c0-4.42-3.58-8-8-8zm0 14c-3.31 0-6-2.69-6-6 0-1.01.25-1.97.7-2.8L5.24 7.74C4.46 8.97 4 10.43 4 12c0 4.42 3.58 8 8 8v3l4-4-4-4v3z" />
              </svg>
            </button>
          </Tooltip>
        </div>
      </div>
      <div
        className={clsx(
          'relative min-h-[5rem] max-h-[16.625rem] lg:max-h-[none] ',
          networkLoading
            ? 'h-[16.625rem] overflow-hidden'
            : 'overflow-y-auto lg:overflow-hidden'
        )}
      >
        <Transition
          {...transitionProps.loading}
          show={networkLoading}
          className="absolute inset-x-0"
        >
          <Loading />
        </Transition>
        <Transition
          {...transitionProps.loaded}
          show={!loading && !error && data?.recentExports?.length === 0}
        >
          <Empty
            title="No Exports"
            messages={
              source === 'dashboard'
                ? ['No exports created yet.']
                : ['No exports created yet on this project.']
            }
            icon={
              <svg
                className="h-32 w-32 text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M0 0h24v24H0z" fill="none"></path>
                <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM17 13l-5 5-5-5h3V9h4v4h3z"></path>
              </svg>
            }
          />
        </Transition>
        <Transition
          show={!loading && !!error && error?.graphQLErrors?.length > 0}
          {...transitionProps.loaded}
        >
          <Empty
            title="Oops! Something went wrong!"
            type="error"
            messages={error?.graphQLErrors.map(({ message }) => message) ?? []}
          />
        </Transition>
        <Transition
          {...transitionProps.loaded}
          show={!error && data?.recentExports.length !== 0}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {data?.recentExports?.map(
              ({
                id,
                media,
                status,
                fileType,
                format,
                createdAt,
                url,
                size,
              }) => (
                <ExportItem
                  key={id}
                  id={id}
                  scope={scope}
                  fileType={fileType}
                  format={format}
                  status={status}
                  media={media}
                  project={media?.project}
                  createdAt={createdAt}
                  url={url}
                  size={size}
                  loading={loading}
                  showProject={source === 'dashboard'}
                />
              )
            )}
          </div>
        </Transition>
      </div>
    </div>
  );
};

export const ProjectExports = React.memo(ProjectExportsComponent);
export default ProjectExportsComponent;
