import React, { Fragment, useCallback, useMemo, useRef, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { FormatPill, Spinner, useAuth, useDebouncy } from '@typevid/ui-shared';
import clsx from 'clsx';
import { ProjectsFormatsSelector } from './projects-formats-selector';
import { useRouter } from 'next/router';
import { AlertProviderProps, useAlert } from 'react-alert';
import { useMutation } from '@apollo/client';
import {
  Mutation,
  CreateProjectInput,
  CreateProjectMutation,
  UserProjectsQuery,
  userProjectsQueryVars,
  Query,
  TemplateFormat,
} from '@typevid/graphql';

const MAX_FORMATS = 12;
const projectsCreateValidate = {
  name: (name: string) => {
    const l = name.trim().length;
    if (l === 0) return 'Enter a project name.';
    if (l > 140) return 'Must be less than 140 characters.';
    return null;
  },
  description: (desciption: string) => {
    const l = desciption.trim().length;
    return l > 300 ? 'Must be less than 300 characters.' : null;
  },
  formats: (formats: TemplateFormat[]) => {
    const l = formats.length;
    return l === 0
      ? 'Select at least 1 format.'
      : l > MAX_FORMATS
      ? `Select no more than ${MAX_FORMATS} formats.`
      : null;
  },
};

const getRecommendedFormat = (arr: TemplateFormat[]) => {
  if (arr.length === 0) return null;
  const v = arr.reduce(
    (a: Array<TemplateFormat & { ix: number }>, v: TemplateFormat) => {
      const smaller = arr.filter(
        ({ id, width, height, orientation }) =>
          width <= v.width &&
          height <= v.height &&
          orientation === v.orientation &&
          id !== v.id
      );
      const larger = arr.filter(
        ({ id, width, height, orientation }) =>
          width >= v.width &&
          height >= v.height &&
          orientation === v.orientation &&
          id !== v.id
      );

      a.push({ ...v, ix: smaller.length + larger.length });
      return a;
    },
    []
  );

  const { ix, ...rest } = v.sort((a, b) => b.ix - a.ix)[0];
  return rest as TemplateFormat;
};

export interface ProjectsCreate {
  shown: boolean;
  onClose: () => void;
}

export const ProjectsCreate: React.FC<ProjectsCreate> = ({
  shown = false,
  onClose,
}) => {
  const router = useRouter();
  const alert = useAlert();
  const { scope } = useAuth();
  const focusedInputRef = useRef<HTMLInputElement>(null);
  const [{ error, name, description, formats }, setState] = useState<{
    name: string;
    description: string;
    formats: TemplateFormat[];
    error: {
      name: string | null;
      description: string | null;
      formats: string | null;
    };
  }>({
    name: '',
    description: '',
    formats: [],

    error: { name: null, description: null, formats: null },
  });

  const [createProject, { loading }] = useMutation<
    { createProject: Mutation['createProject'] },
    CreateProjectInput
  >(CreateProjectMutation, {
    onError: (error) => {
      const errors =
        error.graphQLErrors && error.graphQLErrors.length > 0
          ? error.graphQLErrors
          : [error];

      errors.forEach(({ message }) => {
        alert.error(
          <>
            <h4 className="text-sm font-medium">An error has occurred!</h4>
            {typeof message === 'object' ? (
              (message as string[])?.map((m, i) => (
                <p key={i} className="text-xs">
                  {m}
                </p>
              ))
            ) : (
              <p className="text-xs">{message}</p>
            )}
          </>,
          {
            timeout: 0,
            position: 'top right',
          } as AlertProviderProps
        );
      });
    },
    onCompleted: (data) => {
      router.push(`/${scope}/designer/${data.createProject.templateId}`);
    },
    update(cache, { data }) {
      if (!data) return;
      // Update UserProjects
      const userProjectsData = cache.readQuery<{
        userProjects: Query['userProjects'];
      }>({
        query: UserProjectsQuery,
        variables: userProjectsQueryVars,
      });

      if (userProjectsData?.userProjects?.edges) {
        cache.writeQuery({
          query: UserProjectsQuery,
          variables: userProjectsQueryVars,
          data: {
            userProjects: {
              ...userProjectsData.userProjects,
              totalCount: userProjectsData.userProjects.totalCount + 1,
              edges: [
                {
                  node: { ...data.createProject },
                  cursor: data.createProject.id,
                  __typename: 'MediaEdge',
                },
                ...userProjectsData.userProjects.edges,
              ],
              pageInfo: {
                ...userProjectsData.userProjects.pageInfo,
                startCursor: data.createProject.id,
              },
            },
          },
        });
      }
    },
  });

  const handleOnCreate = useCallback(() => {
    const _error: typeof error = {
      name: projectsCreateValidate.name(name),
      description: projectsCreateValidate.description(description),
      formats: projectsCreateValidate.formats(formats),
    };
    if (
      (Object.keys(_error) as Array<keyof typeof error>).every(
        (k) => !_error[k]
      )
    ) {
      createProject({
        variables: {
          name: name.trim(),
          description: description.trim(),
          formats,
          fps: 30,
        },
      });
    } else {
      setState((s) => ({
        ...s,
        error: _error,
      }));
    }
  }, [createProject, description, formats, name]);

  const handleOnClose = useCallback(() => {
    if (!loading) {
      onClose();
    }
  }, [loading, onClose]);

  const handleBaseFormatChange = useCallback((selected: TemplateFormat) => {
    setState((s) => ({
      ...s,
      formats: s.formats.map((f) => ({ ...f, isBase: selected.id === f.id })),
    }));
  }, []);

  const { baseFormat, baseFormatList, recommendedBaseFormat } = useMemo(() => {
    const baseFormatList = [...formats].sort(
      (a, b) =>
        b.orientation - a.orientation ||
        b.height - a.height ||
        b.width - a.width
    );
    return {
      baseFormatList,
      baseFormat: formats.find(({ isBase }) => isBase),
      recommendedBaseFormat: getRecommendedFormat(baseFormatList),
    };
  }, [formats]);

  useDebouncy(
    () => {
      setState((s) => ({
        ...s,
        error: {
          ...s.error,
          name: projectsCreateValidate.name(name),
        },
      }));
    },
    100,
    [name]
  );

  useDebouncy(
    () => {
      setState((s) => ({
        ...s,
        error: {
          ...s.error,
          description: projectsCreateValidate.description(description),
        },
      }));
    },
    100,
    [description]
  );

  useDebouncy(
    () => {
      setState((s) => ({
        ...s,
        error: {
          ...s.error,
          formats: projectsCreateValidate.formats(formats),
        },
      }));
    },
    100,
    [formats]
  );

  return (
    <div
      className={clsx(
        'fixed inset-0 z-50 overflow-hidden select-none',
        !shown && 'pointer-events-none'
      )}
      aria-labelledby="slide-over-title"
      role="dialog"
      aria-modal="true"
    >
      <Transition show={shown}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            enter="ease-in-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            as="button"
            type="button"
            className={clsx(loading && 'cursor-default')}
            aria-hidden="true"
            onClick={handleOnClose}
          >
            <div
              className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            />
          </Transition.Child>

          <div className="fixed inset-y-0 right-0 sm:pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-200"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-100"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative w-screen max-w-lg">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-200"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  afterEnter={() => focusedInputRef.current?.focus()}
                >
                  <div className="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
                    {!loading && (
                      <button
                        className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={handleOnClose}
                      >
                        <span className="sr-only">Close panel</span>

                        <svg
                          className="h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    )}
                  </div>
                </Transition.Child>

                <div className="h-full flex flex-col bg-white shadow-xl overflow-y-auto">
                  <div className="px-4 sm:px-6 mt-6">
                    <h2
                      id="slide-over-heading"
                      className="text-lg font-medium text-gray-900"
                    >
                      Create a Blank Project
                    </h2>
                  </div>
                  <div className="mt-6 relative flex-1">
                    <div className="absolute inset-0 flex flex-col">
                      <div className="flex-1">
                        <div className="grid gap-4 px-4 sm:px-6">
                          <div>
                            <label
                              htmlFor="name"
                              className="block text-sm font-medium"
                            >
                              Project Name
                            </label>
                            <div className="mt-1 flex">
                              <input
                                ref={focusedInputRef}
                                type="text"
                                name="name"
                                id="name"
                                value={name}
                                className={clsx(
                                  'text-sm focus:outline-none flex-1 p-2 block w-full rounded-md border',
                                  error?.name
                                    ? 'border-red-500 focus:border-red-500'
                                    : 'border-gray-300 focus:ring-indigo-600 focus:border-indigo-600'
                                )}
                                placeholder="My Project"
                                autoComplete="off"
                                autoCorrect="off"
                                onChange={(e) =>
                                  setState((s) => ({
                                    ...s,
                                    name: e.target.value,
                                  }))
                                }
                              />
                            </div>
                            {!!error?.name && (
                              <div className="mt-2 text-sm p-2 pl-3 rounded-[0.25rem] text-red-600 bg-gray-50 border-l-4 border-red-500">
                                <p>{error.name}</p>
                              </div>
                            )}
                          </div>

                          <div>
                            <div className="flex justify-between space-x-2">
                              <label
                                htmlFor="formats"
                                className="block text-sm font-medium"
                              >
                                Template Formats
                              </label>
                              <p className="text-sm">
                                Remaining {formats.length}/{MAX_FORMATS}
                              </p>
                            </div>

                            <div className="mt-1">
                              <ProjectsFormatsSelector
                                formats={formats}
                                error={error?.formats}
                                disabled={formats.length === MAX_FORMATS}
                                onChange={(formats) =>
                                  setState((s) => ({ ...s, formats }))
                                }
                                placeHolder={`Select up to ${MAX_FORMATS} formats`}
                              />
                            </div>
                            {!error?.formats && formats.length > 0 && (
                              <div className="mt-2 space-y-1 text-sm p-2 pl-3 rounded-[0.25rem] bg-gray-50 border-l-4 border-yellow-500">
                                <p>
                                  You can rename these formats later from
                                  project settings. But you{' '}
                                  <span className="font-medium">cannot</span>{' '}
                                  change their dimensions.
                                </p>
                              </div>
                            )}
                          </div>

                          <div>
                            <label
                              htmlFor="baseFormat"
                              className="block text-sm font-medium"
                            >
                              Base Format
                            </label>
                            <div className="mt-1">
                              <Listbox
                                value={baseFormat}
                                onChange={handleBaseFormatChange}
                                disabled={!baseFormat}
                              >
                                <div className="relative ">
                                  <Listbox.Button
                                    className={clsx(
                                      'relative text-sm pr-9 w-full text-left block rounded-md bg-white border border-gray-300 focus:outline-none focus:ring-indigo-600 focus:border-indigo-600',
                                      !baseFormat && 'opacity-50'
                                    )}
                                  >
                                    {baseFormat ? (
                                      <FormatPill {...baseFormat} />
                                    ) : (
                                      <span className="block truncate p-2">
                                        Select template formats first.
                                      </span>
                                    )}
                                    <span className="absolute inset-y-0 right-0 flex items-center pointer-events-none pr-2">
                                      <svg
                                        className="h-5 w-5 text-gray-400"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        stroke="currentColor"
                                      >
                                        <path
                                          d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        ></path>
                                      </svg>
                                    </span>
                                  </Listbox.Button>
                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-sm bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none">
                                      {baseFormatList.map((format) => (
                                        <Listbox.Option
                                          key={format.id}
                                          className={({ active }) =>
                                            `select-none relative pr-9 ${
                                              active ? 'bg-gray-100' : ''
                                            }`
                                          }
                                          value={format}
                                        >
                                          {({ selected }) => (
                                            <>
                                              <FormatPill {...format} />

                                              {selected ? (
                                                <span className="absolute inset-y-0 right-0 flex items-center px-2">
                                                  <svg
                                                    className="h-5 w-5"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                  >
                                                    <path
                                                      fillRule="evenodd"
                                                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                      clipRule="evenodd"
                                                    ></path>
                                                  </svg>
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      ))}
                                    </Listbox.Options>
                                  </Transition>
                                </div>
                              </Listbox>
                            </div>
                            {recommendedBaseFormat &&
                              recommendedBaseFormat.id !== baseFormat?.id && (
                                <div className="mt-2 space-y-1 text-sm p-2 pl-3 rounded-[0.25rem] bg-gray-50 border-l-4 border-blue-500">
                                  <p>
                                    Recommended base format:{' '}
                                    <span className="font-medium">
                                      {recommendedBaseFormat.name} (
                                      {recommendedBaseFormat.sizeLabel})
                                    </span>
                                  </p>
                                </div>
                              )}
                            {!!baseFormat && (
                              <details className="mt-2 space-y-1 text-sm p-2 pl-3 rounded-[0.25rem] bg-gray-50 border-l-4 border-yellow-500">
                                <summary className="list-none marker:hidden">
                                  You{' '}
                                  <span className="font-medium">cannot</span>{' '}
                                  change base format later.{' '}
                                  <span className="text-blue-500 hover:underline cursor-pointer">
                                    view more...
                                  </span>
                                </summary>
                                <p>
                                  Formats are sorted by orientation (portrait
                                  first), then height, then width.
                                  <br />
                                  Base format styles apply to all formats unless
                                  they're edited in a larger or smaller format.
                                  You will start your styling in this format.
                                </p>
                              </details>
                            )}
                          </div>

                          <div>
                            <label
                              htmlFor="description"
                              className="block text-sm font-medium"
                            >
                              Description{' '}
                              <span className="font-normal">(Optional)</span>
                            </label>
                            <div className="mt-1">
                              <textarea
                                id="description"
                                name="description"
                                value={description}
                                rows={2}
                                className={clsx(
                                  'text-sm focus:outline-none flex-1 p-2 block w-full rounded-md border ',
                                  error?.description
                                    ? 'border-red-500 focus:border-red-500'
                                    : 'border-gray-300 focus:ring-indigo-600 focus:border-indigo-600'
                                )}
                                placeholder="Give it a short description"
                                onChange={(e) =>
                                  setState((s) => ({
                                    ...s,
                                    description: e.target.value,
                                  }))
                                }
                              ></textarea>
                            </div>

                            {!!error?.description && (
                              <div className="mt-2 text-sm p-2 pl-3 rounded-[0.25rem] text-red-600 bg-gray-50 border-l-4 border-red-500">
                                <p>{error.description}</p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="mt-6 px-4 py-3 space-x-2 bg-gray-50 text-right sm:px-6">
                        {!loading && (
                          <button
                            type="button"
                            className={clsx(
                              'inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 select-none focus:outline-none focus-visible:ring-2 focus:ring-offset-2 focus:ring-indigo-600'
                            )}
                            onClick={onClose}
                          >
                            Cancel
                          </button>
                        )}

                        <button
                          disabled={loading}
                          type="button"
                          className={clsx(
                            'inline-flex justify-center items-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white transition-all',
                            'select-none hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus:ring-offset-2 focus:ring-indigo-600',
                            loading
                              ? 'bg-indigo-700 cursor-default opacity-50'
                              : 'bg-indigo-600'
                          )}
                          onClick={handleOnCreate}
                        >
                          {loading && (
                            <Spinner className="text-white w-3 h-3 mr-2" />
                          )}{' '}
                          Create
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default ProjectsCreate;
