import { useBreadcrumbSelector } from '@typevid/ui-shared';
import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';

interface MediaMenuProps {
  label: string;
  level: number;
}
export const MediaMenu: React.FC<MediaMenuProps> = ({ label, level }) => {
  const b = useBreadcrumbSelector((s) => s.data?.[level]);

  return (
    <div className="bg-white relative select-none">
      <div className="relative z-10 px-0 sm:px-2 lg:px-4">
        <nav className="flex items-center text-sm h-12">
          <div className="relative">
            {!b?.href ? (
              <span className="ml-4 sm:ml-2 lg:ml-4 bg-gray-200 text-gray-200 border border-transparent px-4 py-2 rounded-md animate-pulse text-sm font-medium">
                Back to main Page
              </span>
            ) : (
              <Link href={b?.href}>
                <a
                  href={b?.href}
                  className={clsx(
                    'inline-flex items-center space-x-1 px-3 py-3 rounded-md leading-none transition-colors focus-md',
                    'hover:bg-gray-100 text-gray-900'
                  )}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-3.5 h-3.5"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    aria-hidden={true}
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                  </svg>
                  <span className="relative px-1">{label}</span>
                </a>
              </Link>
            )}
          </div>
        </nav>
      </div>
      <span className="absolute bottom-0 inset-x-0 h-px bg-gray-100" />
    </div>
  );
};

export default MediaMenu;
