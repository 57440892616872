import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import {
  ApolloError,
  useFragment_experimental,
  useLazyQuery,
} from '@apollo/client';
import {
  Media as MediaType,
  Query,
  UserMediaQuery,
  MediaExportStatusType,
  QueryUserMediaArgs,
  MediasFragments,
} from '@typevid/graphql';
import {
  Layout,
  Empty,
  useSetBreadcrumbState,
  useAuth,
} from '@typevid/ui-shared';
import { Transition } from '@headlessui/react';
import { MediaHeader } from './media-header';
import { MediaExports } from './media-exports';
import clsx from 'clsx';
import { set } from 'object-path-immutable';
import MediaMenu from './media-menu';

export const Media: React.FC = () => {
  const router = useRouter();
  const { scope } = useAuth();
  const setBreadcrumbState = useSetBreadcrumbState();

  const [{ data, error, loading, fromCache }, setState] = useState<{
    data: MediaType | null;
    error: ApolloError | null;
    loading: boolean;
    fromCache: boolean;
  }>({
    data: null,
    error: null,
    loading: true,
    fromCache: true,
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setBreadcrumbState((s) => ({ ...s, show: false })), []);

  const [fetchUserMedia] = useLazyQuery<
    { userMedia: Query['userMedia'] },
    QueryUserMediaArgs
  >(UserMediaQuery, {
    onError(error) {
      setState({ data: null, error, loading: false, fromCache: false });
    },
  });

  const { complete, data: fragmentData } = useFragment_experimental<
    MediaType,
    QueryUserMediaArgs
  >({
    fragment: MediasFragments.MediaBasicWithExports,
    fragmentName: 'MediaBasicWithExports',
    from: {
      __typename: 'Media',
      id: router.query?.mediaId,
    },
  });

  useEffect(() => {
    if (complete && fragmentData) {
      setState({
        data: fragmentData,
        error: null,
        loading: false,
        fromCache: true,
      });
      populateBreadcrumb(fragmentData);
      return;
    }

    setState((s) => ({ ...s, fromCache: false, loading: true }));
    fetchUserMedia({
      variables: { id: router.query?.mediaId as string },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fragmentData, complete]);

  const populateBreadcrumb = useCallback(
    (data: MediaType) => {
      setBreadcrumbState((s) => ({
        show: true,
        data: {
          0: { href: `/${scope}/projects`, label: 'Dashboard' },
          1: s.data?.[1] ?? {
            href: `/${scope}/${data?.project.id}/medias`,
            label: data.project?.name,
          },
          2: {
            href: `/${scope}/${data?.project.id}/${data.id}`,
            label: data.title,
          },
        },
      }));
    },
    [scope, setBreadcrumbState]
  );

  useEffect(() => {
    setBreadcrumbState((s) =>
      s.show ? set(s, 'data.2.href', router.asPath) : s
    );
  }, [router.asPath, setBreadcrumbState]);

  const stats = useMemo(() => {
    if (loading || !data?.exports) {
      return {
        submitted: '--',
        exporting: '--',
        exported: '--',
        failed: '--',
      };
    }
    return {
      submitted: String(
        data?.exports?.filter(
          ({ status }) => status === MediaExportStatusType.Submitted
        ).length ?? 0
      ),
      exporting: String(
        data?.exports?.filter(
          ({ status }) => status === MediaExportStatusType.Exporting
        ).length ?? 0
      ),
      exported: String(
        data?.exports?.filter(
          ({ status }) => status === MediaExportStatusType.Exported
        ).length ?? 0
      ),
      failed: String(
        data?.exports?.filter(
          ({ status }) => status === MediaExportStatusType.Failed
        ).length ?? 0
      ),
    };
  }, [data?.exports, loading]);

  const handleOnOpenEditorClick = useCallback(
    (e: React.MouseEvent | React.KeyboardEvent) => {
      e.preventDefault();
      router.push(`/${scope}/editor/${data?.id}`);
    },
    [data?.id, router, scope]
  );

  if (error) {
    return (
      <Layout title="Media">
        <Transition
          show={!loading && !!error}
          enter={`transition ease-out duration-300 delay-900`}
          enterFrom="transform opacity-0"
          enterTo="transform opacity-100"
          leave="transition ease-in duration-300"
          leaveFrom="transform opacity-100"
          leaveTo="transform opacity-0"
          className="absolute inset-0 top-16"
        >
          <Empty
            title="Oops! Something went wrong!"
            type="error"
            messages={error.graphQLErrors.map(({ message }) => message)}
            actionLabel="Go To Dashboard"
            onActionClick={() => router.push(`/${scope}`)}
          />
        </Transition>
      </Layout>
    );
  }

  return (
    <Layout
      title={data?.title || 'Media'}
      header={
        <>
          <MediaMenu label="Back to Project" level={1} />
          <MediaHeader loading={loading && !fromCache} data={data} />
        </>
      }
    >
      <div className="relative">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <div className="relative space-y-12 py-6">
            <div className="space-y-4">
              <div className="flex justify-between items-center space-x-4">
                <h3 className="leading-none font-medium text-base">
                  Exports Overview
                </h3>
                <span className="h-px flex-1 bg-gray-300" />
              </div>
              <div className="flex -mx-4 sm:mx-0 sm:flex-row sm:divide-x sm:divide-y-0 divide-y flex-col sm:rounded-lg shadow bg-white">
                <div className="flex flex-1 divide-x">
                  <div className="p-4 font-medium inline-flex flex-1 flex-col items-center justify-center text-center">
                    <p className="text-sm uppercase">Submitted</p>
                    <h3
                      className={clsx(
                        'sm:text-6xl text-3xl',
                        !loading
                          ? 'text-gray-900'
                          : 'text-gray-300 animate-pulse'
                      )}
                    >
                      {stats.submitted}
                    </h3>
                  </div>
                  <div className="p-4 font-medium inline-flex flex-1 flex-col items-center justify-center text-center">
                    <p className="text-sm uppercase">Exporting</p>
                    <h3
                      className={clsx(
                        'sm:text-6xl text-3xl',
                        !loading
                          ? 'text-gray-900'
                          : 'text-gray-300 animate-pulse'
                      )}
                    >
                      {stats.exporting}
                    </h3>
                  </div>
                </div>
                <div className="flex flex-1 divide-x">
                  <div className="p-4 font-medium inline-flex flex-1 flex-col items-center justify-center text-center">
                    <p className="text-sm uppercase">Exported</p>
                    <h3
                      className={clsx(
                        'sm:text-6xl text-3xl',
                        !loading
                          ? 'text-gray-900'
                          : 'text-gray-300 animate-pulse'
                      )}
                    >
                      {stats.exported}
                    </h3>
                  </div>
                  <div className="p-4 font-medium inline-flex flex-1 flex-col items-center justify-center text-center">
                    <p className="text-sm uppercase">Failed</p>
                    <h3
                      className={clsx(
                        'sm:text-6xl text-3xl',
                        !loading
                          ? 'text-gray-900'
                          : 'text-gray-300 animate-pulse'
                      )}
                    >
                      {stats.failed}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <MediaExports onOpenEditorClick={handleOnOpenEditorClick} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Media;
