import {
  ApolloError,
  useFragment_experimental,
  useLazyQuery,
} from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import {
  Project as ProjectType,
  ProjectsFragments,
  Query,
  QueryUserProjectArgs,
  UserProjectQuery,
} from '@typevid/graphql';

import { set } from 'object-path-immutable';
import { useAuth, useSetBreadcrumbState } from '@typevid/ui-shared';
import { useRouter } from 'next/router';

export const useProject = (syncBreadcrumb = false) => {
  const router = useRouter();
  const { scope } = useAuth();
  const [isCreateShown, setIsCreateShown] = useState<boolean>(false);
  const setBreadcrumbState = useSetBreadcrumbState();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setBreadcrumbState((s) => ({ ...s, show: false })), []);

  const [{ data, error, loading, fromCache }, setState] = useState<{
    data: ProjectType | null;
    error: ApolloError | null;
    loading: boolean;
    fromCache: boolean;
  }>({
    data: null,
    error: null,
    loading: true,
    fromCache: true,
  });

  const [fetchUserProject] = useLazyQuery<
    {
      userProject: Query['userProject'];
    },
    QueryUserProjectArgs
  >(UserProjectQuery, {
    variables: { id: router.query?.projectId as string },
    onError(error) {
      setState({ data: null, error, loading: false, fromCache: false });
    },
  });

  const { complete, data: fragmentData } = useFragment_experimental<
    ProjectType,
    QueryUserProjectArgs
  >({
    fragment: ProjectsFragments.ProjectFull,
    fragmentName: 'ProjectFull',
    from: {
      __typename: 'Project',
      id: router.query?.projectId,
    },
  });

  useEffect(() => {
    if (complete && fragmentData) {
      setState({
        data: fragmentData,
        error: null,
        loading: false,
        fromCache: true,
      });
      populateBreadcrumb(fragmentData);
      return;
    }

    setState((s) => ({ ...s, fromCache: false }));
    fetchUserProject({
      variables: { id: router.query?.projectId as string },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fragmentData, complete]);

  const populateBreadcrumb = useCallback(
    (data: ProjectType) => {
      setBreadcrumbState((s) => ({
        show: true,
        data: {
          0: { href: `/${scope}/projects`, label: 'Dashboard' },
          1: { href: `/${scope}/${data.id}/medias`, label: data.name },
        },
      }));
    },
    [scope, setBreadcrumbState]
  );

  useEffect(() => {
    if (syncBreadcrumb) {
      setBreadcrumbState((s) =>
        s.show ? set(s, 'data.1.href', router.asPath) : s
      );
    }
  }, [router.asPath, setBreadcrumbState, syncBreadcrumb]);

  return {
    data,
    error,
    loading,
    fromCache,
    scope,
    isCreateShown,
    setIsCreateShown,
  };
};
