import React, { useCallback, useEffect, useMemo } from 'react';
import {
  MediaEdge,
  OrderDirection,
  Query,
  QueryUserProjectMediasArgs,
  UserProjectMediasQuery,
  userProjectMediasQueryVars,
  MediaExportStatusType,
  MediaModeType,
  Media,
  PaginationArgs,
} from '@typevid/graphql';
import Link from 'next/link';
import { Empty, EditorStatus, useAuth, formatDate } from '@typevid/ui-shared';
import { NetworkStatus, useLazyQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { Column } from 'react-table';
import { Table } from '../table/table';
import { Tooltip } from 'react-tippy';
import clsx from 'clsx';
import { ParsedUrlQueryInput } from 'querystring';

type TableMediaColumnProps = Media & {
  submitted: string;
  exporting: string;
  exported: string;
  failed: string;
  link: string;
  loading: boolean;
};

const mapProjectMediasData = (
  edges: MediaEdge[],
  scope: string | undefined | null,
  loading: boolean
): TableMediaColumnProps[] => {
  return (
    edges.map(({ node }) => {
      if (node.mode === MediaModeType.Edit) {
        return {
          ...node,
          submitted: '-',
          exporting: '-',
          exported: '-',
          failed: '-',
          link: `/${scope}/editor/${node.id}`,
          loading,
        };
      }
      return {
        ...node,
        loading,
        submitted: String(
          node.exports.filter(
            ({ status }) => status === MediaExportStatusType.Submitted
          ).length
        ),
        exporting: String(
          node.exports.filter(
            ({ status }) => status === MediaExportStatusType.Exporting
          ).length
        ),
        exported: String(
          node.exports.filter(
            ({ status }) => status === MediaExportStatusType.Exported
          ).length
        ),
        failed: String(
          node.exports.filter(
            ({ status }) => status === MediaExportStatusType.Failed
          ).length
        ),
        link: `/${scope}/${node.project.id}/${node.id}`,
      };
    }) ?? []
  );
};

interface MediasTableProps {
  onCreateClick: () => void;
  total: number;
}

export const MediasTable: React.FC<MediasTableProps> = ({
  total,
  onCreateClick,
}) => {
  const router = useRouter();
  const { scope } = useAuth();

  const [
    fetchUserProjectMedias,
    { loading, data, error, networkStatus, refetch },
  ] = useLazyQuery<
    { userProjectMedias: Query['userProjectMedias'] },
    QueryUserProjectMediasArgs
  >(UserProjectMediasQuery, {
    pollInterval: 60000,
    notifyOnNetworkStatusChange: true,
  });

  const pageCount = useMemo(
    () =>
      Math.ceil(
        (data?.userProjectMedias?.totalCount ?? 0) /
          (userProjectMediasQueryVars.page.first ?? 1)
      ),
    [data?.userProjectMedias?.totalCount]
  );

  const mappedData = useMemo(
    () =>
      mapProjectMediasData(
        data?.userProjectMedias?.edges ?? [],
        scope,
        loading
      ),
    [data?.userProjectMedias?.edges, loading, scope]
  );

  const { sortBy, cursorPosition, cursorId } = useMemo(() => {
    let sortBy = [
      {
        id: userProjectMediasQueryVars.order.field as string,
        desc:
          userProjectMediasQueryVars.order.direction === OrderDirection.Desc,
      },
    ];

    if (router.query) {
      sortBy = [
        {
          id:
            (router.query?.sortBy as string) ??
            userProjectMediasQueryVars.order.field,
          desc: router.query?.sortDir === OrderDirection.Asc ? false : true,
        },
      ];
    }

    return {
      sortBy,
      cursorPosition: router.query?.cPos as string | undefined,
      cursorId: router.query?.cId as string | undefined,
    };
  }, [router.query]);

  let page: PaginationArgs = userProjectMediasQueryVars.page;
  if (cursorPosition === 'before') {
    page = {
      before: cursorId,
      last: userProjectMediasQueryVars.page.first,
    };
  }
  if (cursorPosition === 'after') {
    page = {
      first: userProjectMediasQueryVars.page.first,
      after: cursorId,
    };
  }
  useEffect(() => {
    fetchUserProjectMedias({
      variables: {
        ...userProjectMediasQueryVars,
        id: router.query?.projectId as string,
        order: {
          field: sortBy[0].id,
          direction: sortBy[0].desc ? OrderDirection.Desc : OrderDirection.Asc,
        },
        page,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = useCallback(
    (pos: 'before' | 'after', sortBy: { id: string; desc?: boolean }) => {
      const endCursor = data?.userProjectMedias?.pageInfo?.endCursor;
      const startCursor = data?.userProjectMedias?.pageInfo?.startCursor;

      router.replace(
        {
          query: {
            ...router.query,
            cPos: pos,
            cId: pos === 'before' ? startCursor : endCursor,
          },
        },
        undefined,
        { shallow: true }
      );

      fetchUserProjectMedias({
        variables: {
          id: router.query?.projectId as string,
          order: {
            field: sortBy.id,
            direction: sortBy.desc ? OrderDirection.Desc : OrderDirection.Asc,
          },
          page:
            pos === 'before'
              ? {
                  last: userProjectMediasQueryVars.page.first,
                  before: startCursor,
                }
              : {
                  first: userProjectMediasQueryVars.page.first,
                  after: endCursor,
                },
        },
      });
    },
    [
      data?.userProjectMedias?.pageInfo?.endCursor,
      data?.userProjectMedias?.pageInfo?.startCursor,
      fetchUserProjectMedias,
      router,
    ]
  );

  const sortData = useCallback(
    (rule: { id: string; desc?: boolean }) => {
      if (loading || error) {
        return;
      }

      let query: ParsedUrlQueryInput = {
        ...router.query,
        cId: undefined,
        cPos: undefined,
        sortBy: rule.id,
        sortDir: rule.desc ? OrderDirection.Desc : OrderDirection.Asc,
      };
      // remove pagination args
      query = JSON.parse(JSON.stringify(query));

      router.replace(
        {
          query,
        },
        undefined,
        { shallow: true }
      );

      fetchUserProjectMedias({
        variables: {
          id: router.query?.projectId as string,
          order: {
            field: rule.id,
            direction: rule.desc ? OrderDirection.Desc : OrderDirection.Asc,
          },
          page: { first: userProjectMediasQueryVars.page.first },
        },
      });
    },
    [error, fetchUserProjectMedias, loading, router]
  );

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center space-x-4">
        <h3 className="leading-none font-medium text-base">
          Medias
          {data?.userProjectMedias?.totalCount
            ? ` (${data?.userProjectMedias?.totalCount})`
            : ''}
        </h3>
        <span className="h-px flex-1 bg-gray-300" />
        {data?.userProjectMedias?.edges?.length !== 0 && !error && (
          <div>
            <Tooltip
              size="small"
              position="top"
              disabled={loading}
              arrow={true}
              duration={150}
              html={
                <span>
                  Refresh
                  <span className="block text-xs">
                    (Auto-refreshes every 60 seconds)
                  </span>
                </span>
              }
            >
              <button
                className={clsx(
                  'flex justify-center items-center px-2 py-1 rounded-md bg-white border border-gray-300 select-none focus:outline-none focus-visible:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                  loading
                    ? 'bg-gray-300 text-gray-400'
                    : 'hover:bg-gray-50 text-gray-500 focus-lg'
                )}
                disabled={loading}
                onClick={() => (refetch ? refetch() : false)}
              >
                <svg
                  className={clsx('h-5 w-5', loading && 'animate-spin')}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 4V1L8 5l4 4V6c3.31 0 6 2.69 6 6 0 1.01-.25 1.97-.7 2.8l1.46 1.46C19.54 15.03 20 13.57 20 12c0-4.42-3.58-8-8-8zm0 14c-3.31 0-6-2.69-6-6 0-1.01.25-1.97.7-2.8L5.24 7.74C4.46 8.97 4 10.43 4 12c0 4.42 3.58 8 8 8v3l4-4-4-4v3z" />
                </svg>
              </button>
            </Tooltip>
          </div>
        )}
      </div>
      <div className="flex flex-col -mx-4 sm:mx-0">
        {data?.userProjectMedias?.edges?.length === 0 ? (
          <Empty
            title="No Media Found!"
            messages={["Let's get started shall we?"]}
            actionLabel="Create a New Media"
            onActionClick={onCreateClick}
            icon={
              <svg
                className="h-32 w-32 text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M2.5 3.5a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-11zm2-2a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6v7zm6.258-6.437a.5.5 0 0 1 .507.013l4 2.5a.5.5 0 0 1 0 .848l-4 2.5A.5.5 0 0 1 6 12V7a.5.5 0 0 1 .258-.437z"></path>
              </svg>
            }
          />
        ) : (
          <Table<TableMediaColumnProps>
            data={mappedData}
            columns={mediasTableColumns}
            loading={loading}
            sortBy={sortBy}
            networkLoading={
              networkStatus === NetworkStatus.loading ||
              networkStatus === NetworkStatus.setVariables
            }
            error={error}
            count={data?.userProjectMedias?.totalCount ?? 0}
            pageCount={pageCount}
            pageSize={userProjectMediasQueryVars.page.first ?? 1}
            hasNextPage={
              data?.userProjectMedias?.pageInfo?.hasNextPage ?? false
            }
            hasPreviousPage={
              data?.userProjectMedias?.pageInfo?.hasPreviousPage ?? false
            }
            fetchData={fetchData}
            sortData={sortData}
          />
        )}
      </div>
    </div>
  );
};

const mediasTableColumns: Column<TableMediaColumnProps>[] = [
  {
    Header: () => <span className="block w-[15rem]">Title</span>,
    accessor: 'title',
    Cell: ({ value, row }) =>
      row?.original ? (
        <Link href={row.original.link as string}>
          <a
            href={row.original.link as string}
            className="block w-[15rem] truncate font-medium text-sm hover:text-indigo-500 hover:underline"
          >
            {value as string}
          </a>
        </Link>
      ) : null,
  },
  {
    Header: <span className="block w-20">Mode</span>,
    accessor: 'mode',
    Cell: ({ value, row: { original } }) =>
      original.loading ? (
        <EditorStatus status={'···' as MediaModeType} />
      ) : (
        <EditorStatus status={value} />
      ),
  },
  {
    Header: 'Submitted',
    accessor: 'submitted',
    Cell: ({ value, row: { original } }) => (
      <span className="block text-sm"> {original.loading ? '···' : value}</span>
    ),
    disableSortBy: true,
  },
  {
    Header: 'Exporting',
    accessor: 'exporting',
    Cell: ({ value, row: { original } }) => (
      <span className="block text-sm"> {original.loading ? '···' : value}</span>
    ),
    disableSortBy: true,
  },
  {
    Header: 'Exported',
    accessor: 'exported',
    Cell: ({ value, row: { original } }) => (
      <div className="flex items-center space-x-0.5 text-sm">
        {value !== '0' && value !== '-' && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="w-4 h-4 text-green-500"
            fill="currentColor"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z" />{' '}
          </svg>
        )}
        <span>{original.loading ? '···' : value}</span>
      </div>
    ),
    disableSortBy: true,
  },
  {
    Header: 'Failed',
    accessor: 'failed',
    Cell: ({ value, row: { original } }) => (
      <div className="flex items-center space-x-0.5 text-sm">
        {value !== '0' && value !== '-' && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="w-4 h-4 text-red-500"
            fill="currentColor"
          >
            <path d="M11 15h2v2h-2v-2zm0-8h2v6h-2V7zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
          </svg>
        )}
        <span>{original.loading ? '···' : value}</span>
      </div>
    ),
    disableSortBy: true,
  },

  {
    Header: () => <span className="block w-36">Created</span>,
    accessor: 'createdAt',
    Cell: ({ value, row }) => {
      return (
        <span className="block truncate text-sm">
          {row?.original?.loading ? '···' : formatDate(value as string, 'D')}
        </span>
      );
    },
  },
];

export default MediasTable;
