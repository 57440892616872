import React, { useCallback, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import {
  Layout,
  Empty,
  transitionProps,
  useAuth,
  LayoutHeader,
} from '@typevid/ui-shared';
import { useLazyQuery } from '@apollo/client';
import {
  UserProjectsQuery,
  Query,
  QueryUserProjectsArgs,
  OrderDirection,
  userProjectsQueryVars,
} from '@typevid/graphql';

import { useRouter } from 'next/router';
import clsx from 'clsx';
import { ProjectsListItemLoading } from './projects-list-item-loading';
import { ProjectsListItem, useOnToggleFavorite } from './projects-list-item';

import { DashboardMenu } from '../dashboard/dashboard-menu';

import Link from 'next/link';

const Loading: React.FC = React.memo(() => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 animate-pulse">
      {Array(9)
        .fill(0)
        .map((_, i) => (
          <ProjectsListItemLoading key={i} />
        ))}
    </div>
  );
});

export const Projects: React.FC = () => {
  const router = useRouter();
  const onToggleFavorite = useOnToggleFavorite();
  const { scope } = useAuth();

  const [fetchUserProjects, { loading, data, error }] = useLazyQuery<
    { userProjects: Query['userProjects'] },
    QueryUserProjectsArgs
  >(UserProjectsQuery, { notifyOnNetworkStatusChange: true });

  useEffect(() => {
    fetchUserProjects({
      variables: userProjectsQueryVars,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = useCallback(
    (pos: 'before' | 'after', sortBy = { id: 'createdAt', desc: true }) => {
      const endCursor = data?.userProjects?.pageInfo?.endCursor;
      const startCursor = data?.userProjects?.pageInfo?.startCursor;
      fetchUserProjects({
        variables: {
          order: {
            field: sortBy.id,
            direction: sortBy.desc ? OrderDirection.Desc : OrderDirection.Asc,
          },
          page:
            pos === 'before'
              ? { last: userProjectsQueryVars.page.first, before: startCursor }
              : { first: userProjectsQueryVars.page.first, after: endCursor },
        },
      });
    },
    [
      data?.userProjects?.pageInfo?.endCursor,
      data?.userProjects?.pageInfo?.startCursor,
      fetchUserProjects,
    ]
  );

  return (
    <Layout
      title="Projects - Dashboard"
      header={
        <>
          <DashboardMenu activeTab="projects" />
          <LayoutHeader title="Projects">
            <div className="flex">
              <Link href={`/${scope}/projects/new`}>
                <a
                  href={`/${scope}/projects/new`}
                  className="inline-flex w-full sm:w-auto justify-center sm:justify-start px-4 py-2 rounded-md text-sm font-medium text-white select-none bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <svg
                    className="-ml-1 mr-2 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                  </svg>
                  <span>New Project</span>
                </a>
              </Link>
            </div>
          </LayoutHeader>
        </>
      }
    >
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <div className="space-y-12 py-6">
          <div className="space-y-4">
            <div className="relative">
              <Transition
                {...transitionProps.loading}
                show={loading}
                className="relative"
              >
                <Loading />
              </Transition>
              <Transition
                {...transitionProps.loaded}
                show={
                  !loading && !error && data?.userProjects?.edges?.length === 0
                }
              >
                <Empty
                  title="No Projects"
                  messages={["Let's get started shall we?"]}
                  actionLabel="Create New Project"
                  onActionClick={() => router.push(`/${scope}/projects/new`)}
                  icon={
                    <svg
                      className="h-40 w-40 text-gray-300"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3zm-8.322.12C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139z" />
                    </svg>
                  }
                />
              </Transition>
              <Transition
                show={!loading && !!error}
                {...transitionProps.loaded}
              >
                <Empty
                  title="Oops! Something went wrong!"
                  type="error"
                  messages={
                    error?.graphQLErrors.map(({ message }) => message) ?? []
                  }
                  actionLabel="Go To Dashboard"
                  onActionClick={() => router.push(`/${scope}`)}
                />
              </Transition>

              <Transition
                {...transitionProps.loaded}
                show={
                  !!(
                    !loading &&
                    !error &&
                    data?.userProjects?.edges &&
                    data?.userProjects?.edges?.length > 0
                  )
                }
              >
                <div>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {data?.userProjects?.edges?.map(({ node }) => (
                      <ProjectsListItem
                        key={node.id}
                        id={node.id}
                        name={node.name}
                        count={node.totalMedias}
                        templateId={node.templateId}
                        date={node.createdAt}
                        favorite={node.favorite}
                        scope={scope}
                        onFavoriteClick={(...args) =>
                          onToggleFavorite(...args, node)
                        }
                      />
                    ))}
                  </div>
                  {/* Pagination */}
                  {!loading && (
                    <div
                      className={clsx(
                        'pt-6 flex items-center justify-between',
                        !data?.userProjects?.pageInfo?.hasPreviousPage &&
                          !data?.userProjects?.pageInfo?.hasNextPage &&
                          'hidden'
                      )}
                    >
                      <div className="flex-1 flex justify-between">
                        <button
                          type="button"
                          onClick={() => fetchData('before')}
                          disabled={
                            !data?.userProjects?.pageInfo?.hasPreviousPage
                          }
                          className={clsx(
                            'inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white select-none',
                            !data?.userProjects?.pageInfo?.hasPreviousPage
                              ? 'opacity-50 cursor-not-allowed'
                              : 'hover:bg-gray-100 focus-lg'
                          )}
                        >
                          Previous
                        </button>

                        <button
                          type="button"
                          onClick={() => fetchData('after')}
                          disabled={!data?.userProjects?.pageInfo?.hasNextPage}
                          className={clsx(
                            'inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white select-none',
                            !data?.userProjects?.pageInfo?.hasNextPage
                              ? 'opacity-50 cursor-not-allowed'
                              : 'hover:bg-gray-100 focus-lg'
                          )}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </Transition>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Projects;
