import React, { useCallback, useEffect, useMemo } from 'react';
import {
  MediaExport,
  MediaExportEdge,
  OrderDirection,
  Query,
  QueryUserMediaExportsArgs,
  UserMediaExportsQuery,
  userMediaExportsQueryVars,
  MediaExportStatusType,
  PaginationArgs,
} from '@typevid/graphql';
import filesize from 'filesize';
import { NetworkStatus, useLazyQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { Table } from '../table/table';
import { Column } from 'react-table';
import Link from 'next/link';
import { EditorStatus, Empty, formatDate, useAuth } from '@typevid/ui-shared';
import { Tooltip } from 'react-tippy';
import clsx from 'clsx';
import { ParsedUrlQueryInput } from 'querystring';

type TableExportsColumnProps = MediaExport & {
  link: string;
  loading: boolean;
};

const mapMediaExportsData = (
  edges: MediaExportEdge[],
  scope: string | undefined | null,
  loading: boolean
): TableExportsColumnProps[] => {
  return (
    edges.map(({ node }) => ({
      ...node,
      loading,
      link: `/${scope}/${node.media?.project?.id}/${node.media?.id}/${node.id}`,
    })) ?? []
  );
};

interface MediaExportsProps {
  onOpenEditorClick: (e: React.MouseEvent | React.KeyboardEvent) => void;
}

export const MediaExports: React.FC<MediaExportsProps> = ({
  onOpenEditorClick,
}) => {
  const router = useRouter();
  const { scope } = useAuth();
  const [
    fetchUserMediaExports,
    { loading, data, error, networkStatus, refetch },
  ] = useLazyQuery<
    { userMediaExports: Query['userMediaExports'] },
    QueryUserMediaExportsArgs
  >(UserMediaExportsQuery, {
    notifyOnNetworkStatusChange: true,
    pollInterval: 60000,
  });

  const { sortBy, cursorPosition, cursorId } = useMemo(() => {
    let sortBy = [
      {
        id: userMediaExportsQueryVars.order.field as string,
        desc: userMediaExportsQueryVars.order.direction === OrderDirection.Desc,
      },
    ];

    if (router.query) {
      sortBy = [
        {
          id:
            (router.query?.sortBy as string) ??
            userMediaExportsQueryVars.order.field,
          desc: router.query?.sortDir === OrderDirection.Asc ? false : true,
        },
      ];
    }

    return {
      sortBy,
      cursorPosition: router.query?.cPos as string | undefined,
      cursorId: router.query?.cId as string | undefined,
    };
  }, [router.query]);

  useEffect(() => {
    let page: PaginationArgs = userMediaExportsQueryVars.page;
    if (cursorPosition === 'before') {
      page = {
        before: cursorId,
        last: userMediaExportsQueryVars.page.first,
      };
    }
    if (cursorPosition === 'after') {
      page = {
        first: userMediaExportsQueryVars.page.first,
        after: cursorId,
      };
    }

    fetchUserMediaExports({
      variables: {
        ...userMediaExportsQueryVars,
        id: router.query?.mediaId as string,
        order: {
          field: sortBy[0].id,
          direction: sortBy[0].desc ? OrderDirection.Desc : OrderDirection.Asc,
        },
        page,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pageCount = useMemo(
    () =>
      Math.ceil(
        (data?.userMediaExports?.totalCount ?? 0) /
          (userMediaExportsQueryVars.page.first ?? 1)
      ),
    [data?.userMediaExports?.totalCount]
  );

  const mappedData = useMemo(
    () =>
      mapMediaExportsData(data?.userMediaExports?.edges ?? [], scope, loading),
    [data?.userMediaExports?.edges, loading, scope]
  );

  const fetchData = useCallback(
    (pos: 'before' | 'after', sortBy: { id: string; desc?: boolean }) => {
      const endCursor = data?.userMediaExports?.pageInfo?.endCursor;
      const startCursor = data?.userMediaExports?.pageInfo?.startCursor;

      router.replace(
        {
          query: {
            ...router.query,
            cPos: pos,
            cId: pos === 'before' ? startCursor : endCursor,
          },
        },
        undefined,
        { shallow: true }
      );
      fetchUserMediaExports({
        variables: {
          id: router.query?.mediaId as string,
          order: {
            field: sortBy.id,
            direction: sortBy.desc ? OrderDirection.Desc : OrderDirection.Asc,
          },
          page:
            pos === 'before'
              ? {
                  last: userMediaExportsQueryVars.page.first,
                  before: startCursor,
                }
              : {
                  first: userMediaExportsQueryVars.page.first,
                  after: endCursor,
                },
        },
      });
    },
    [
      data?.userMediaExports?.pageInfo?.endCursor,
      data?.userMediaExports?.pageInfo?.startCursor,
      fetchUserMediaExports,
      router,
    ]
  );

  const sortData = useCallback(
    (rule: { id: string; desc?: boolean }) => {
      if (loading || error) {
        return;
      }

      let query: ParsedUrlQueryInput = {
        ...router.query,
        cId: undefined,
        cPos: undefined,
        sortBy: rule.id,
        sortDir: rule.desc ? OrderDirection.Desc : OrderDirection.Asc,
      };
      // remove pagination args
      query = JSON.parse(JSON.stringify(query));

      router.replace(
        {
          query,
        },
        undefined,
        { shallow: true }
      );

      fetchUserMediaExports({
        variables: {
          id: router.query?.mediaId as string,
          order: {
            field: rule.id,
            direction: rule.desc ? OrderDirection.Desc : OrderDirection.Asc,
          },
          page: { first: userMediaExportsQueryVars.page.first },
        },
      });
    },
    [error, fetchUserMediaExports, loading, router]
  );

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center space-x-4">
        <h3 className="leading-none font-medium text-base">
          Media Exports{' '}
          {data?.userMediaExports?.totalCount
            ? ` (${data?.userMediaExports?.totalCount})`
            : ''}
        </h3>
        <span className="h-px flex-1 bg-gray-300" />
        {data?.userMediaExports?.edges?.length !== 0 && !error && (
          <div>
            <Tooltip
              size="small"
              position="top"
              disabled={loading}
              arrow={true}
              duration={150}
              html={
                <span>
                  Refresh
                  <span className="block text-xs">
                    (Auto-refreshes every 60 seconds)
                  </span>
                </span>
              }
            >
              <button
                className={clsx(
                  'flex justify-center items-center px-2 py-1 rounded-md bg-white border border-gray-300 select-none focus:outline-none focus-visible:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                  loading
                    ? 'bg-gray-300 text-gray-500'
                    : 'hover:bg-gray-50 focus-lg'
                )}
                disabled={loading}
                onClick={() => (refetch ? refetch() : false)}
              >
                <svg
                  className={clsx('h-5 w-5', loading && 'animate-spin')}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 4V1L8 5l4 4V6c3.31 0 6 2.69 6 6 0 1.01-.25 1.97-.7 2.8l1.46 1.46C19.54 15.03 20 13.57 20 12c0-4.42-3.58-8-8-8zm0 14c-3.31 0-6-2.69-6-6 0-1.01.25-1.97.7-2.8L5.24 7.74C4.46 8.97 4 10.43 4 12c0 4.42 3.58 8 8 8v3l4-4-4-4v3z" />
                </svg>
              </button>
            </Tooltip>
          </div>
        )}
      </div>

      <div className="flex flex-col -mx-4 sm:mx-0">
        {data?.userMediaExports?.edges?.length === 0 ? (
          <Empty
            title="No Export Found!"
            messages={["Let's get started shall we?"]}
            actionLabel="Open Editor"
            onActionClick={onOpenEditorClick}
            icon={
              <svg
                className="h-40 w-40 text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM17 13l-5 5-5-5h3V9h4v4h3z"></path>
              </svg>
            }
          />
        ) : (
          <Table<TableExportsColumnProps>
            data={mappedData}
            columns={mediaExportsColumns}
            loading={loading}
            sortBy={sortBy}
            networkLoading={
              networkStatus === NetworkStatus.loading ||
              networkStatus === NetworkStatus.setVariables
            }
            error={error}
            count={data?.userMediaExports?.totalCount ?? 0}
            pageCount={pageCount}
            pageSize={userMediaExportsQueryVars.page.first ?? 1}
            hasNextPage={data?.userMediaExports?.pageInfo?.hasNextPage ?? false}
            hasPreviousPage={
              data?.userMediaExports?.pageInfo?.hasPreviousPage ?? false
            }
            fetchData={fetchData}
            sortData={sortData}
          />
        )}
      </div>
    </div>
  );
};

const mediaExportsColumns: Column<TableExportsColumnProps>[] = [
  {
    Header: () => <span className="block">Type</span>,
    accessor: 'fileType',
    Cell: ({ value, row: { original } }) =>
      original ? (
        <Link href={original.link}>
          <a
            href={original.link}
            className="block truncate w-14 text-sm font-medium uppercase hover:underline hover:text-indigo-500"
          >
            {value}
          </a>
        </Link>
      ) : null,
  },
  {
    Header: () => <span className="block">Format</span>,
    accessor: 'format',
    Cell: ({ value, row: { original } }) =>
      original ? (
        <Link href={original.link}>
          <a
            href={original.link}
            className="block truncate max-w-[9rem] text-sm font-medium hover:underline hover:text-indigo-500"
          >
            {value}
          </a>
        </Link>
      ) : null,
  },
  // {
  //   Header: () => <span className="block max-w-[15rem] min-w-[10rem]">ID</span>,
  //   accessor: 'id',
  //   disableSortBy: true,
  //   Cell: ({
  //     value,
  //     row: { original },
  //   }: {
  //     value: unknown;
  //     row: {
  //       original: Record<string, unknown>;
  //     };
  //   }) =>
  //     original ? (
  //       <Link href={original.link as string}>
  //         <a
  //           href={original.link as string}
  //           onClick={original.onClick as (e: React.MouseEvent) => void}
  //           className="block max-w-[15rem] min-w-[10rem] truncate text-sm text-blue-600 hover:underline"
  //         >
  //           {value as string}
  //         </a>
  //       </Link>
  //     ) : null,
  // },
  {
    Header: <span className="block w-20">Status</span>,
    accessor: 'status',
    Cell: ({ value, row: { original } }) =>
      original.loading ? (
        <EditorStatus status={'···' as MediaExportStatusType} />
      ) : (
        <EditorStatus status={value} />
      ),
  },

  {
    Header: () => <span className="block max-w-[9rem]">Created</span>,
    accessor: 'createdAt',
    Cell: ({ value, row: { original } }) => (
      <span className="block truncate text-sm">
        {original.loading ? '···' : formatDate(value as string, 'D')}
      </span>
    ),
  },
  {
    Header: () => <span className="block max-w-[9rem]">Exported</span>,
    accessor: 'exportedAt',
    Cell: ({ value, row: { original } }) => (
      <span className="block truncate text-sm">
        {original.loading
          ? '···'
          : value
          ? formatDate(value as string, 'D')
          : '-'}
      </span>
    ),
  },
  {
    Header: () => <span className="block">Size</span>,
    accessor: 'size',
    Cell: ({ value }) => (
      <span className="block truncate text-sm">
        {value ? filesize(parseFloat(value as string), { round: 2 }) : '-'}
      </span>
    ),
  },
  {
    Header: () => <span className="block">Action</span>,
    accessor: 'url',
    disableSortBy: true,
    Cell: ({ value, row: { original } }) => {
      if (
        original.status === MediaExportStatusType.Exported &&
        !!original.url
      ) {
        return (
          <a
            href={value ?? '#'}
            download={true}
            className="inline-flex select-none items-center px-2 py-1 border border-gray-300 rounded-md shadow-sm text-sm font-medium bg-white hover:bg-gray-50 focus-lg"
          >
            Download
          </a>
        );
      }
      return <span className="py-1 border border-transparent">-</span>;
    },
  },
];

export default MediaExports;
