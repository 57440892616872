import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import {
  ApolloError,
  useFragment_experimental,
  useLazyQuery,
} from '@apollo/client';
import {
  Query,
  MediasFragments,
  MediaExport,
  MediaExportStatusType,
  QueryUserMediaExportArgs,
  UserMediaExportQuery,
} from '@typevid/graphql';
import { exportsFileTypes } from '@typevid/shared-data';
import {
  Layout,
  Empty,
  useSetBreadcrumbState,
  formatDate,
  EditorStatus,
  createConsecutiveStr,
  Coin,
  useAuth,
} from '@typevid/ui-shared';
import { Transition } from '@headlessui/react';
import { ExportHeader } from './export-header';
import filesize from 'filesize';
import Link from 'next/link';
import { set } from 'object-path-immutable';
import { MediaMenu } from '../media/media-menu';

export const Export: React.FC = () => {
  const router = useRouter();
  const { scope } = useAuth();
  const setBreadcrumbState = useSetBreadcrumbState();
  const [{ data, error, loading, fromCache }, setState] = useState<{
    data: MediaExport | null;
    error: ApolloError | null;
    loading: boolean;
    fromCache: boolean;
  }>({
    data: null,
    error: null,
    loading: true,
    fromCache: true,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setBreadcrumbState((s) => ({ ...s, show: false })), []);

  const [fetchUserMediaExport] = useLazyQuery<
    { userMediaExport: Query['userMediaExport'] },
    QueryUserMediaExportArgs
  >(UserMediaExportQuery, {
    onError(error) {
      setState({ data: null, error, loading: false, fromCache: false });
    },
  });

  const { complete, data: fragmentData } = useFragment_experimental<
    MediaExport,
    QueryUserMediaExportArgs
  >({
    fragment: MediasFragments.MediaExport,
    fragmentName: 'MediaExport',
    from: {
      __typename: 'MediaExport',
      id: router.query?.exportId,
    },
  });

  useEffect(() => {
    if (complete && fragmentData) {
      setState({
        data: fragmentData,
        error: null,
        loading: false,
        fromCache: true,
      });
      populateBreadcrumb(fragmentData);
      return;
    }

    setState((s) => ({ ...s, fromCache: false, loading: true }));
    fetchUserMediaExport({
      variables: { id: router.query?.exportId as string },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fragmentData, complete]);

  const populateBreadcrumb = useCallback(
    (data: MediaExport) => {
      setBreadcrumbState((s) => ({
        show: true,
        data: {
          0: { href: `/${scope}/projects`, label: 'Dashboard' },
          1: s.data?.[1] ?? {
            href: `/${scope}/${data.media?.project?.id}/medias`,
            label: data.media?.project?.name,
          },
          2: s.data?.[2] ?? {
            href: `/${scope}/${data.media?.project?.id}/${data.media?.id}`,
            label: data.media?.title,
          },
          3: {
            href: `/${scope}/${data.media?.project?.id}/${data.media?.id}/${data.id}`,
            label: `${data.fileType?.toUpperCase()} - ${data.format}`,
          },
        },
      }));
    },
    [scope, setBreadcrumbState]
  );

  useEffect(() => {
    setBreadcrumbState((s) =>
      s.show ? set(s, 'data.3.href', router.asPath) : s
    );
  }, [router.asPath, setBreadcrumbState]);

  const file = useMemo(
    () => exportsFileTypes.find(({ id }) => id === data?.fileType),
    [data?.fileType]
  );

  if (error) {
    return (
      <Layout title="Export">
        <Transition
          show={!loading && !!error}
          enter={`transition ease-out duration-300 delay-900`}
          enterFrom="transform opacity-0"
          enterTo="transform opacity-100"
          leave="transition ease-in duration-300"
          leaveFrom="transform opacity-100"
          leaveTo="transform opacity-0"
          className="absolute inset-0 top-16"
        >
          <Empty
            title="Oops! Something went wrong!"
            type="error"
            messages={error.graphQLErrors.map(({ message }) => message)}
            actionLabel="Go To Dashboard"
            onActionClick={() => router.push(`/${scope}`)}
          />
        </Transition>
      </Layout>
    );
  }

  return (
    <Layout
      title={
        `${data?.fileType?.toUpperCase()} ${data?.format} - ${
          data?.media?.title
        }` || 'Media'
      }
      header={
        <>
          <MediaMenu label="Back to Media" level={2} />
          <ExportHeader loading={loading && !fromCache} data={data} />
        </>
      }
    >
      <div className="relative">
        <div className="max-w-xl mx-auto py-6 px-0 sm:px-6 lg:px-8">
          <div className="relative space-y-12 py-6">
            {loading && !fromCache && (
              <div className="h-96 sm:rounded-lg  bg-gray-300 animate-pulse"></div>
            )}
            {!loading && data && (
              <div className="text-sm font-medium text-gray-900 bg-white divide-y divide-gray-200 sm:rounded-lg shadow">
                {data?.status === MediaExportStatusType.Exported && (
                  <div className="grid sm:grid-cols-2 grid-cols-1 sm:space-y-0 space-y-4 py-6 px-4 sm:px-6">
                    <div className="space-y-4">
                      <div>
                        <h5 className="text-gray-500 uppercase text-xs font-medium">
                          Quantity
                        </h5>
                        <p>
                          {data?.quantity} {file?.unit}
                          {data?.quantity !== 1 ? 's' : ''}
                        </p>
                      </div>
                      <div>
                        <h5 className="text-gray-500 uppercase text-xs font-medium">
                          Credits
                        </h5>

                        <Coin
                          label={`${data?.credits}`}
                          width={16}
                          height={16}
                        />
                      </div>
                    </div>
                    <div className="space-y-4">
                      <div>
                        <h5 className="text-gray-500 uppercase text-xs font-medium">
                          Size
                        </h5>
                        <p>
                          {data?.size
                            ? filesize(parseFloat(data?.size), { round: 2 })
                            : '-'}
                        </p>
                      </div>

                      <div>
                        <h5 className="text-gray-500 uppercase text-xs font-medium">
                          Download Link
                        </h5>
                        <div className="w-full border border-gray-300 rounded-sm px-2 py-1 bg-white whitespace-nowrap overflow-y-auto no-scrollbar cursor-text">
                          <a
                            href={data?.url ?? '#'}
                            download={true}
                            className="hover:text-indigo-500 hover:underline font-normal"
                          >
                            {data?.url}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {data?.status === MediaExportStatusType.Failed && (
                  <div className="sm:space-y-0 space-y-4 py-4 px-4 sm:px-6">
                    <div>
                      <h5 className="text-gray-500 uppercase text-xs font-medium">
                        Error
                      </h5>
                      <div className="max-h-96 mt-1 w-full py-2 px-4 rounded-md bg-gray-100 overflow-auto">
                        <pre>{JSON.stringify(data?.error ?? {}, null, 2)}</pre>
                      </div>
                    </div>
                  </div>
                )}

                <div className="grid sm:grid-cols-2 grid-cols-1 sm:space-y-0 space-y-4 py-6 px-4 sm:px-6">
                  <div className="space-y-4">
                    <div>
                      <h5 className="text-gray-500 uppercase text-xs font-medium">
                        Scenes
                      </h5>
                      <p className="break-words">
                        {data?.scenes
                          ? createConsecutiveStr(
                              data?.scenes
                                ?.split(',')
                                .map((v) => parseInt(v, 10) + 1),
                              ['', '']
                            )
                          : 'All Scenes'}
                      </p>
                    </div>
                    <div>
                      <h5 className="text-gray-500 uppercase text-xs font-medium">
                        File Type
                      </h5>
                      <p className="uppercase">{data?.fileType}</p>
                    </div>
                    <div>
                      <h5 className="text-gray-500 uppercase text-xs font-medium">
                        File Type Settings
                      </h5>
                      <div>
                        {data?.fileSettings &&
                        Object.keys(data?.fileSettings).length > 0 ? (
                          <div className="rounded-md space-x-1 capitalize">
                            {Object.keys(data.fileSettings)
                              .map((k) => `${k}: ${data.fileSettings[k]}`)
                              .join(', ')}
                          </div>
                        ) : (
                          '-'
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="space-y-4">
                    <div>
                      <h5 className="text-gray-500 uppercase text-xs font-medium">
                        Format
                      </h5>
                      <p className="break-words">{data?.format}</p>
                    </div>

                    <div>
                      <h5 className="text-gray-500 uppercase text-xs font-medium">
                        Width
                      </h5>
                      <p>{data?.width}</p>
                    </div>
                    <div>
                      <h5 className="text-gray-500 uppercase text-xs font-medium">
                        Height
                      </h5>
                      <p>{data?.height}</p>
                    </div>
                  </div>
                </div>

                <div className="grid sm:grid-cols-2 grid-cols-1 sm:space-y-0 space-y-4 py-6 px-4 sm:px-6">
                  <div className="space-y-4">
                    <div>
                      <h5 className="text-gray-500 uppercase text-xs font-medium">
                        ID
                      </h5>
                      <p>{data?.id}</p>
                    </div>
                    <div>
                      <h5 className="text-gray-500 uppercase text-xs font-medium">
                        Status
                      </h5>
                      <p>
                        <EditorStatus
                          status={data?.status as MediaExportStatusType}
                        />
                      </p>
                    </div>
                    <div>
                      <h5 className="text-gray-500 uppercase text-xs font-medium">
                        Source
                      </h5>
                      <p>{data?.source}</p>
                    </div>
                  </div>
                  <div className="space-y-4">
                    <div>
                      <h5 className="text-gray-500 uppercase text-xs font-medium">
                        Created by
                      </h5>
                      <p className="break-words">
                        {data?.creator?.firstName} {data?.creator?.lastName}
                      </p>
                    </div>
                    <div>
                      <h5 className="text-gray-500 uppercase text-xs font-medium">
                        Created at
                      </h5>
                      <p>{formatDate(data?.createdAt, 'B')}</p>
                    </div>

                    <div>
                      <h5 className="text-gray-500 uppercase text-xs font-medium">
                        Exported at
                      </h5>
                      <p>{formatDate(data?.exportedAt, 'B') ?? '-'}</p>
                    </div>
                  </div>
                </div>

                <div className="grid sm:grid-cols-2 grid-cols-1 sm:space-y-0 space-y-4 py-6 px-4 sm:px-6">
                  <div className="space-y-4">
                    <div>
                      <h5 className="text-gray-500 uppercase text-xs font-medium">
                        Project
                      </h5>
                      <p>
                        <Link href={`/${scope}/${data?.media?.project?.id}`}>
                          <a
                            className="hover:text-indigo-600"
                            href={`/${scope}/${data?.media?.project?.id}`}
                          >
                            {data?.media?.project?.name}
                          </a>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="space-y-4">
                    <div>
                      <h5 className="text-gray-500 uppercase text-xs font-medium">
                        Media
                      </h5>
                      <p>
                        <Link
                          href={`/${scope}/${data?.media?.project?.id}/${data?.media?.id}`}
                        >
                          <a
                            className="hover:text-indigo-600"
                            href={`/${scope}/${data?.media?.project?.id}/${data?.media?.id}`}
                          >
                            {data?.media?.title}
                          </a>
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Export;
