import { useAuth } from '@typevid/ui-shared';
import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';

const PROJECT_TABS = [
  { id: 'project', label: 'Project', path: '' },
  { id: 'medias', label: 'Medias', path: 'medias' },
  { id: 'docs', label: 'Docs', path: 'docs' },
  { id: 'settings', label: 'Settings', path: 'settings' },
];

interface ProjectMenuProps {
  activeTab: string;
  projectId?: string;
}
export const ProjectMenu: React.FC<ProjectMenuProps> = ({
  activeTab,
  projectId,
}) => {
  const { scope } = useAuth();

  if (!projectId) {
    return null;
  }
  return (
    <div className="bg-white relative select-none">
      <div className="relative z-10 px-0 sm:px-2 lg:px-4">
        <nav className="flex items-center text-sm h-12">
          {PROJECT_TABS.map(({ id, label, path }) => (
            <div key={id} className="relative">
              <Link href={`/${scope}/${projectId}/${path}`}>
                <a
                  href={`/${scope}/${projectId}/${path}`}
                  className={clsx(
                    'inline-flex px-3 py-3 rounded-md leading-none transition-colors focus-md',
                    activeTab === id
                      ? 'text-gray-900'
                      : 'text-gray-500 hover:bg-gray-100 hover:text-gray-900'
                  )}
                >
                  <span className="relative px-1">
                    {label}
                    {activeTab === id && (
                      <span className="absolute inset-x-0 -bottom-[1.075rem] h-0.5 bg-gray-700" />
                    )}
                  </span>
                </a>
              </Link>
            </div>
          ))}
        </nav>
      </div>
      <span className="absolute bottom-0 inset-x-0 h-px bg-gray-100" />
    </div>
  );
};

export default ProjectMenu;
