import React from 'react';
import Link from 'next/link';
import { EditorStatus, useAuth, LayoutHeader } from '@typevid/ui-shared';
import { Media } from '@typevid/graphql';

export interface MediaHeaderProps {
  loading: boolean;
  data?: Media | null;
}

export const MediaHeader: React.FC<MediaHeaderProps> = ({ loading, data }) => {
  const { scope } = useAuth();
  return (
    <LayoutHeader
      loading={loading}
      title={data?.title ?? ''}
      titleTag={data?.mode ? <EditorStatus status={data?.mode} /> : null}
    >
      <div className="flex items-center space-x-2">
        {loading || !data ? (
          <span className="w-full sm:w-auto bg-gray-200 text-gray-200 border border-transparent px-4 py-2 rounded-md animate-pulse text-sm font-medium">
            Open Editor
          </span>
        ) : (
          <Link href={`/${scope}/editor/${data?.id}`}>
            <a
              href={`/${scope}/editor/${data?.id}`}
              className="inline-flex w-full sm:w-auto justify-center sm:justify-start px-4 py-2 border border-gray-200 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 select-none focus:outline-none focus-visible:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <svg
                className="-ml-1 mr-2 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                enableBackground="new 0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
              >
                <g>
                  <rect fill="none" height="24" width="24" />
                </g>
                <g>
                  <g>
                    <g>
                      <polygon points="3,17.25 3,21 6.75,21 17.81,9.94 14.06,6.19" />
                    </g>
                    <g>
                      <path d="M20.71,5.63l-2.34-2.34c-0.39-0.39-1.02-0.39-1.41,0l-1.83,1.83l3.75,3.75l1.83-1.83C21.1,6.65,21.1,6.02,20.71,5.63z" />
                    </g>
                  </g>
                </g>
              </svg>
              <span>Open Editor</span>
            </a>
          </Link>
        )}
      </div>
    </LayoutHeader>
  );
};

export default MediaHeader;
