import React from 'react';
import { Layout, LayoutHeader } from '@typevid/ui-shared';
import { DashboardExports } from './dashboard-exports';
import { DashboardMedias } from './dashboard-medias';
import { DashboardProjects } from './dashboard-projects';
import { DashboardMenu } from './dashboard-menu';

export const Dashboard: React.FC = () => {
  return (
    <Layout title="Dashboard" header={<DashboardMenu activeTab="dashboard" />}>
      <LayoutHeader title="Dashboard" />
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <div className="space-y-12 py-6">
          <DashboardExports />
          <DashboardMedias />
          <DashboardProjects />
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
